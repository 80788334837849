'use strict';

const videoServerHostName = 'tv.9st.one';
const commandURL = '/cgi-bin/remote.cgi';

let AuthService = require('./AuthService');

class BTServer  {

    sendDataQuery (options,completion) {
	fetch(options.url,{
	    method: "GET",
	    headers: options.headers || {},
	    body: options.data || null,
	})
		     .then((response) => {
			 if (response.status >= 200 && response.status < 300) {
			     return response;
			 }
			 throw {
			     result: "ERR",
			     success: false,
			     unknownError: response.status != 401
			 }
			 
		     })
            
		     .then((response) => {
			 return response.json();
			 
		     })
		     .then((results) => {
			 if (results.result == "OK") {
			     return completion(results);
			 }
			 throw { results: results }
		     })
		     .catch((err) => {
			 //console.log("BTServer error: " + err.message);
			 return completion(err);
		     })
    }

    
    sendCommand (action, actionValues, completion) {
        AuthService.getAuthInfo((err, authInfo) => {
            // fetch the JSON here, then call completion(JSON)

//                  'DS' : authInfo.ds,  
//                  'SID' : authInfo.sid,
//                  'UID' : authInfo.uid,


            fetch(commandURL, {
              method: "POST",
              headers: {
                  'Accept':'application/json',
                  'Content-Type':'application/json',
		  'X-TVAUTH' : authInfo.token,
                  'X-JSON' : "true"
              },
	      credentials: "include",
             body: JSON.stringify({'act': action, 'actv': actionValues})
            })
            .then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                }
                throw {
                    result: "ERR",
                    success: false,
                    unknownError: response.status != 401
                }
                
            })
            
            .then((response) => {
                return response.json();
                
            })
            .then((results) => {
                if (results.result == "OK") {
                    return completion(results);
		}
                throw { results: results }

            })
            .catch((err) => {
                return completion(err);
            })
            
            
        });                                           
          
        
        
    }
    
    
    
}

module.exports =  new BTServer;