require('es6-promise').polyfill();
require('whatwg-fetch');

var md5 = require('blueimp-md5');
//var AsyncStorage = require('react-native').AsyncStorage;
var _ = require('lodash');

const useridKey = 'userid';
const userkeyKey = 'userkey';
const authServerHostName = 'tv.9st.one';
//const commandURL = 'https://' + authServerHostName + '/cgi-bin/remote.cgi';
const commandURL = '/cgi-bin/remote.cgi';


class AuthService {
    
    verifyLogin(cb) {
	var mytoken = localStorage.getItem('ba');
	//var myid = localStorage.getItem('userid');
	//var mykey = localStorage.getItem('userkey');
	//if ((!myid || !mykey)) { return cb(); }
	if (!mytoken) { return cb(); }

	this.login({token: mytoken}, (logincb) => {
		if (logincb.success == true) {
		    //console.log("Successful login - AS: " + logincb.authInfo);
		    return cb(null, logincb.authInfo);
                    //return cb(); // for testing login window
		} else {
		    //console.log("Unsuccessful login - AS u:" + myid + " k: " + mykey);
		    return cb({badCredentials: logincb.badCredentials, unknownError: logincb.unknownError},null);
		}
	    });
	    
    }

    getAuthInfo(cb){

//	var myid = localStorage.getItem('userid');
//	var mykey = localStorage.getItem('userkey');
	var mytoken = localStorage.getItem('ba');
//	if (!myid || !mykey) { 
	if (!mytoken) { 
//	    //console.log("returning because of no myid or mykey");
	    return cb(); 
	} else {
//	    //console.log("happy with myid and mykey");
	}

	//var auth = this.genAuth({userid: myid, userkey: mykey, token: mytoken});
	var auth = {token: mytoken};

	return cb(null, auth);
	
    }

    genAuth(creds) {

	var uid = Math.floor((Math.random() * 10000000000) + 1);
	var digestString = md5(uid + creds.userid + creds.userkey);
	return { 'uid': uid, 'ds': digestString , 'sid': creds.userid };
    }

    login(creds, cb) {
	var auth = {};
	var headersVar;
	if (creds.token && creds.token.match(/\w+/)) {
	    // refresh token
	    headersVar = {
		'Accept':'application/json',
		'Content-Type':'application/json',
		'X-TVAUTH' : creds.token,
		'X-JSON' : "true"
	    };
	} else {
	    auth = this.genAuth(creds);
	    headersVar = {
		'Accept':'application/json',
		'Content-Type':'application/json',
		'DS' : auth.ds,  
		'SID' : auth.sid,
		'UID' : auth.uid,
		'X-JSON' : "true"
	    };
	}

	fetch(commandURL, {
		method: "POST",
		    headers: headersVar,
		    body: JSON.stringify({'act': 'verifycreds'}) 
		    })
	    .then((response)=> {
		//console.log("response 1");
		    if (response.status >= 200 && response.status < 300) {
			return response;
			//return cb({success: true});
		    }
		    throw {
	        	success: false,
	        	badCredentials: response.status == 401,
	        	unknownError: response.status != 401
		    }

		})

	    .then((response)=> {
		//console.log("response 2");
		    return response.json();
		})
	    .then((results)=>{
		//console.log("response 3");
		if (results.result == "OK") {
		    //console.log("Got OK result");
		    //console.log("Got orgName: " + results.orgName);
		    localStorage.removeItem('userid');
		    localStorage.removeItem('userkey');
		    localStorage.setItem('organization',results.orgName);
		    localStorage.setItem('ba',results.token);
		    auth.token = results.token;
		    return cb({success:true, authInfo: auth});
		}
	    })
	   .catch((err) => {
	       return cb(err);
	   })

    }

}

module.exports = new AuthService();
