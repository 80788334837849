'use strict';

var React = require('react');
var ReactDOM = require('react-dom');


var {
    Component
} = React;



class View extends Component {

    componentDidMount() {


    }

    constructor(props) {
	super(props);

    }

    render() {

	return (<div {...this.props}>{this.props.children}</div>);
	
    }


}

module.exports = View;