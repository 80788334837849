'use strict';

var React = require('react');
var ReactDOM = require('react-dom');


var {
    Component
} = React;

let AuthService = require('./AuthService');
var Login = require('./Login');

var AppContainer = require('./AppContainer');

class IPTV extends Component {


    constructor(props) {
        super(props);
        this.state = {
            showProgress: false,
            success: false,
            isLoggedIn: false,
            checkingAuth: true
	    
        }
	
    }
    

    componentDidMount() {
	var self = this;
	AuthService.verifyLogin((err, authInfo) => {
	    if (err && (err.badCredentials || err.unknownError)) {
		self.setState({checkingAuth: false,
			       isLoggedIn: false});
	    } else {
		if (!self.state.isLoggedIn) {
		    self.setState({
			checkingAuth: false,
			isLoggedIn: authInfo && authInfo.token && authInfo.token.match(/\w+/),
			authInformation: authInfo,
		    })
		}
	    }
	});
    }


    onLogin() {
	//console.log("logged in (onLogin called)");
	this.setState({isLoggedIn: true});
    }

    render() {

	var self = this;

	if (this.state.isLoggedIn) {
	    return (
		<AppContainer />
		    );
	    
	} else if (this.state.checkingAuth){
	    return (
		<h2>Checking auth...</h2>
	    )

	} else {    
		    //
	    return (
		    <Login onLogin={()=>self.onLogin()} /> 
		    );
	}
    }

}

module.exports = IPTV;
