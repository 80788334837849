'use strict';

var React = require('react');
var ReactDOM = require('react-dom');

var View = require('./View');
var Text = require('./Text');
var Lockup = require('./Lockup');
var LargeVideoModal = require('./LargeVideoModal');
var TimerMixin = require('react-timer-mixin');
var { Grid, Row, Col, Button, ButtonGroup, Modal, Nav, Navbar, NavItem, MenuItem, NavDropdown } = require('react-bootstrap');

var globalServerName = 'tv.9st.one';

import BTServer from './BTServer';
import Video from './Video';





var {
    Component
} = React;



class Browse extends Component {

    componentDidMount() {
	//if (this.props.selected) { this.fetchFeed(); }
	this.fetchFeed(); // Preload all the data
    }

    constructor(props) {
	super(props);

	this.state = {
	    showModal: false,
	    showPlayer: false,
	    loadOnce: false,
	    modalVidObj: {},
	    videoobj: {},
	    dataSourceRaw: [],
	    dataSourceSelected: [],
	    results: {},
	    showProgress: true,
	    channels: [],
	    genres: [],
	    times: [],
	    selectedData: "",
	    selectedSection: "genres",
	    diskQuota: {},

	}

    }

    fetchFeed(){
	if (this.state.loadOnce === true) { return; }
	this.setState({loadOnce: true});

	var self = this;

        BTServer.sendCommand('qtnowsmartsearch',["featuredgenres|","limit|16000"],(request) => {
	    //console.log("request.result = " + request.result);


	    var results = {};
	    results.genres = {};
	    results.channels = {};
	    results.times = {};
	    results.movies = []; 
	    results.nexttwo = []; 
	    
	    
	    var seen = {};
	    

            if (request.result == "OK") {
		//console.log("Browse fetchfeed returned with data");
                self.setState({dataSourceRaw: request.payload,
                               showProgress: false
                              });
		if (request.quota) {
		    self.setState({diskQuota: request.quota});
		}

		var date = new Date();
		var gmtepoch = parseInt(date.getTime()/1000);


		var payload = request.payload;

		if (payload.length > 0) {


		    var times = {};
		    times.start = new Date();
		    times.stop = new Date();
		    times.payload = [];
		    times.hourslots = {};


		    for (var x = 0; x < payload.length; x++) {
			//console.log("Iterating over item " + x);
			
			var entry = payload[x];
			
			entry.listid = x;
			
			
			if (!entry.category || !entry.epgid || !entry.hlsurl) { continue; }
			if (entry.category === "Adults only") { continue; } // hard coded override for now		    


			// Stash Genres
			if (!results.genres[entry.category]) {
			    results.genres[entry.category] = [];
			}
			results.genres[entry.category].push(entry);
			


			// Stash channels
			if (!results.channels[entry.channame]) {
			    results.channels[entry.channame] = [];
			}
			results.channels[entry.channame].push(entry);
			
			// Custom groupings

			// Stash Movies
			if (entry.epgid.match(/MV/)) {
			    results.movies.push(entry);
			    //			//console.log("Added movie: " + entry.epgid);
			} 
			
			// Stash next two hours
			if (entry.gmtepochend > gmtepoch && entry.gmtepochstart < gmtepoch + 7201) {
			    results.nexttwo.push(entry);
			}
			
			// Stash start and stop times
			
			var startTime = new Date(entry.gmtepochstart * 1000);
			var stopTime = new Date(entry.gmtepochend * 1000);
			
			//times.start = (startTime.valueOf() < times.start.valueOf()) ? startTime : times.start;
			if (stopTime.valueOf() > times.stop.valueOf()) {
			    times.stop = stopTime;
			}
			times.payload.push(entry);
			
		    }		    
		    
		    
		    times.diff = times.stop.valueOf() - times.start.valueOf();
		    times.diffhours = (((times.diff/1000)/60)/60);
		    
		    var nowtimehours = date;
		    nowtimehours.setMinutes(0);
		    nowtimehours.setSeconds(0);
		    nowtimehours.setMilliseconds(0);
		    
		    for (var i = 0; i < times.diffhours; i++) {
			times.hourslots[nowtimehours.valueOf()] = [];
			nowtimehours.setHours(nowtimehours.getHours() + 1);
		    }
		    
		    
		    for (var j = 0; j < times.payload.length; j++) {
			var entry = times.payload[j];
			var startTime = entry.gmtepochstart * 1000;
			var stopTime = entry.gmtepochend * 1000;
			for (var playTimes in times.hourslots) {
			    if (startTime < (parseInt(playTimes) + 3600000) && stopTime > parseInt(playTimes)) {
				times.hourslots[playTimes].push(entry);
			    }
			}
		    }
		    
		    results.times = times.hourslots;
		    
		    var genrekeys = Object.keys(results.genres);
		    genrekeys.sort();
		    
		    var timeskeys = Object.keys(results.times);
		    timeskeys.sort();
		    
		    var channelkeys = Object.keys(results.channels);
		    channelkeys.sort();
		    
		    // Data should come to us sorted by date already, but just in case
		    results.nexttwo.sort( function(a, b) { return a.gmtepochstart - b.gmtepochstart; } );
		    
		    self.setState({times: timeskeys, channels: channelkeys, genres: genrekeys, results: results});
		    
		    true;
		    
		}
	    } else {
		if (request.message) {
		    //console.log("There was an error: " + request.message);
		}
	    }
	    
	    
        }); // end BTServerCommand
	
    } // end fetchFeed
    

    chooseResults(dataName,data) {
	this.setState({selectedData: dataName});
	this.setState({dataSourceSelected: data});
    }

    chooseSection(data) {
	this.setState({selectedSection: data});
    }

    close() {
	this.setState({ showModal: false });
	this.setState({modalVidObj: {} });
    }

    showVideo(videoObject) {
	//console.log("Clicked " + videoObject.title );
	this.setState({modalVidObj: videoObject});
	this.setState({showModal: true});
    }

    closePlayer() {
	this.setState({showPlayer: false});
	this.setState({videoobj: {}});
    }

    playVideo(videoObject) {
	this.setState({showModal: false});
	this.setState({showPlayer: true});
	this.setState({videoobj: videoObject});
	this.setState({modalVidObj: {}});
    }





    render() {


	//return (<div></div>);
	var videoObj = this.state.videoobj || {};
	var selectedSection  = this.state.selectedSection;
	var selectedData = this.state.selectedData;
	var lgenres = this.state.genres;
	var lchannels = this.state.channels;
	var ltimes = this.state.times;

	var lresults= this.state.results;

//		return (<View key={eachSection} style={{height: "18px", margin: "3px"}} onClick={this.chooseResults.bind(this,eachSection,this.state.results[selectedSection][eachSection])} >{eachSection}</View>)


/*

	    <ButtonGroup style={{paddingTop: "10px", paddingBottom: "10px", width: "100%"}}>
	    <Button onClick={this.chooseSection.bind(this,"genres")} active={selectedSection === "genres"}>By Genre</Button>
	    <Button onClick={this.chooseSection.bind(this,"channels")} active={selectedSection === "channels"}>By Channel</Button>
	    <Button onClick={this.chooseSection.bind(this,"times")} active={selectedSection === "times"}>By Time</Button>
	    </ButtonGroup>



//		return (<Button key={eachSection} onClick={this.chooseResults.bind(this,eachSection,this.state.results[selectedSection][eachSection])}  active={selectedData === eachSection}>{eachSection}</Button>)

*/




/*
	    <Col xs={3} sm={2} md={2} lg={2} style={{overflowY: "scroll", height: "90vh", margin: 0, padding: 0}}>


	    <ButtonGroup vertical style={{width: "100%" }}>

	    { this.state[selectedSection].map((eachSection)=>{

		return (<Button key={eachSection} onClick={this.chooseResults.bind(this,eachSection,this.state.results[selectedSection][eachSection])}  active={selectedData === eachSection}>{eachSection}</Button>)

	    })}

	    </ButtonGroup>

	    </Col>
*/

	var genChildrenData = [];
	var genSportsData = [];

	if (this.state.results.genres) {
	    genChildrenData = this.state.results.genres["Children"];
	    genSportsData = this.state.results.genres["Sports event"];
	    //console.log("Running with data now");
	}

	return (
	    <div style={{paddingTop: "10px"}}>

	    <View style={{margin: 0}}>
	    <Grid style={{border: "0px solid red", }} fluid={true}>

	    <Row style={{border: "0px dashed pink",  padding: "0px", flex: "none"}}>
	    <Col md={12} xs={12} sm={12} lg={12} style={{border: "0px solid orange", margin: 0, padding: 0}}>


	    { this.state.showProgress ? <View style={{padding: "10px", cursor: "progress"}}> <h2>Loading Program Data, Please Wait...</h2> </View> :

	      <Navbar  browse={true} fluid={true}>
		<Nav>


	    <NavItem eventKey="nexttwo" onClick={this.chooseResults.bind(this,'nexttwo',this.state.results.nexttwo)} active={selectedData === 'nexttwo'}>Next 2 Hours</NavItem>
	    <NavItem eventKey="movies" onClick={this.chooseResults.bind(this,'movies',this.state.results.movies)} active={selectedData === 'movies'}>Movies</NavItem>
	    <NavItem eventKey="children1" onClick={this.chooseResults.bind(this,"Children",genChildrenData)} active={selectedData === "Children"}>Children</NavItem>
	    <NavItem eventKey="sports1" onClick={this.chooseResults.bind(this,"Sports",genSportsData)} active={selectedData === "Sports"}>Sports</NavItem>


	    <NavDropdown title="All Genres" id="dropdown-genres" className="browse-dropdown">
	    { lgenres.map((eachSection)=>{

		return (<MenuItem  key={eachSection} onClick={this.chooseResults.bind(this,eachSection,this.state.results.genres[eachSection])}  active={selectedData === eachSection}>{eachSection}</MenuItem>)

	    })}
	    </NavDropdown>


	    <NavDropdown title="All Channels" id="dropdown-channels" className="browse-dropdown">
	    { lchannels.map((eachSection)=>{
		var chanaff;
		if (lresults.channels[eachSection][0].chanaff) { chanaff = "(" + lresults.channels[eachSection][0].chanaff + ")" }
		return (<MenuItem key={eachSection} onClick={this.chooseResults.bind(this,eachSection,this.state.results.channels[eachSection])}  active={selectedData === eachSection}>{eachSection} {chanaff}</MenuItem>)

	    })}
	    </NavDropdown>

	    <NavDropdown title="All Times" id="dropdown-times" className="browse-dropdown">
	    { ltimes.map((eachSection)=>{

		var sectionDate = new Date(eachSection * 1); // The multiplication converts the string to an int
		var sectionHourStart = sectionDate.getHours();
		if (sectionHourStart === 0) { sectionHourStart = 12; }
		if (sectionHourStart > 12) { sectionHourStart = sectionHourStart - 12; }
		var sectionHourEnd = sectionHourStart + 1;
		if (sectionHourEnd > 12) { sectionHourEnd = sectionHourEnd - 12; }
		//sectionHourStart = sectionHourStart + ':00';
		//sectionHourEnd = sectionHourEnd + ':00';
		return (<MenuItem key={eachSection} onClick={this.chooseResults.bind(this,eachSection,this.state.results.times[eachSection])}  active={selectedData === eachSection}>

		    <span style={{width: "12%", display: "inline-block", textAlign: "right"}}>{sectionHourStart}</span>
		    <span style={{width: "15%", display: "inline-block", textAlign: "left"}}>:00</span>
		    <span style={{width: "18%", display: "inline-block", textAlign: "right"}}>-</span>
		    <span style={{width: "12%", display: "inline-block", textAlign: "right"}}>{sectionHourEnd}</span>
		    <span style={{width: "15%", display: "inline-block", textAlign: "left"}}>:00</span>

		    </MenuItem>)

	    })}
	    </NavDropdown>





	    </Nav>
	    </Navbar>


		}

	    </Col>
	    </Row>

	    <Row style={{border: "0px dashed yellow",  padding: "0px", flex: 1}}>

	    <Col xs={12} sm={12} md={12} lg={12} style={{overflowY: "scroll",  margin: 0, padding: 0, paddingLeft: 10, maxHeight: "calc(100vh - 135px)"}}>

 	    { this.state.dataSourceSelected.map((eachShow) => {

		var titleToSee = eachShow.title;

		//if (eachShow.epgid.match(/EP|SH|SP/)  && eachShow.subtitle){
		//console.log("Sub: " + eachShow.subtitle);
		if (eachShow.subtitle){
		    titleToSee = eachShow.subtitle;
		}
		// titleToSee isn't displayed anywhere here
		return (
		    <Lockup 
		    key={eachShow.progid} 
		    clicker={this.showVideo.bind(this,eachShow)} 
		    entry={eachShow} 
		    style={{padding: "10px", verticalAlign: "top", display: "inline-block"}}
		    orientation="p" >{titleToSee}</Lockup>
		)
	    })}

	    </Col>

	    </Row>

	    <Row style={{flex: "none", height: "10px"}}><Col xs={12} sm={12} lg={12} md={12} style={{ height: "10px"}} /></Row>
	    </Grid>
	    </View>

	    <LargeVideoModal diskQuota={this.state.diskQuota} show={this.state.showModal} onHide={this.close.bind(this)} videoObject={this.state.modalVidObj} watch={this.playVideo.bind(this,this.state.modalVidObj)}  >
	    </LargeVideoModal>

	    <Modal keyboard={true} bsClass="videoModal modal" dialogClassName="videoPlayerModal" show={this.state.showPlayer} onHide={this.closePlayer.bind(this)} style={{width: "100%", height: "auto", position: "absolute", top: 0, left: 0, verticalAlign: "middle"}}>
	    <Modal.Header  id="videomodal" closeButton><Modal.Title>{videoObj.title} - {videoObj.channame}</Modal.Title></Modal.Header>
	    <Modal.Body>
	    <Video key={videoObj.progid} title="Video" url={videoObj.hlsurl} />
	    </Modal.Body>
	    </Modal>



	    </div>
	);
	
    }


}

module.exports = Browse;


/*

	    <NavItem eventKey="movies" onClick={this.chooseResults.bind(this,"movies",this.state.results.movies)} active={selectedData === "movies"}>Movies</NavItem>
	    <NavItem eventKey="children1" onClick={this.chooseResults.bind(this,"Children",this.state.results.genres["Children"])} active={selectedData === "Children"}>Children</NavItem>
	    <NavItem eventKey="sports1" onClick={this.chooseResults.bind(this,"Sports",this.state.results.genres["Sports event"])} active={selectedData === "Sports"}>Sports</NavItem>





*/


/*

		var sectionDate = new Date(eachSection);
		var sectionHourStart = sectionDate.getHours() + 1;
		if (sectionHourStart > 12) { sectionHourStart = sectionHourStart - 12; }
		var sectionHourEnd = sectionHourStart + 1;
		if (sectionHourEnd > 12) { sectionHourEnd = sectionHourEnd - 12; }
		var sectionHourPrint = sectionHour + ':00 - ' + sectionHourEnd + ':00';


*/