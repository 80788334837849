'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
var Series = require('./Series');
var View = require('./View');
var Text = require('./Text');
var BTServer = require('./BTServer');

var { Button, Grid, Row, Col } = require('react-bootstrap');

var globalServerName = "tv.9st.one";

var {
    Component
} = React;

class LockupLg extends Component {

    componentDidMount() {
    }

    constructor(props) {
	super(props);
	this.state = {
	    alert: false,
	    alertStatus: '',
	    alertMessage: '',
	    videoObj: props.videoObject,
	    showSeriesModal: false,
	}

    }

    componentWillReceiveProps(newProps) {
	this.setState({videoObj: newProps.videoObject});
	if (newProps.videoObject && newProps.videoObject.epgid) {

	    this.setState({alert: false,
			   alertTitle: '',
			   alertMessage: '',
			   showSeriesModal: false,
			   seriesVidObj: newProps.videoObject,
	    });
	    
	    
	}
    }

    epgImgUrl (epgid,width,height) {
	var url = "?is=Md";
	if (height>width) { // portrait
	    url = url + "&as=2x3";
	} else { // landscape
	    url = url + "&as=4x3";
	} 
	// with text
	url = url + "&it=true&s=" + epgid;
	//var finalURL = "https://" + globalServerName + "/tmsdata/pi" + url;
	var finalURL = "/tmsdata/pi" + url;
	return finalURL;
    }


    makeImgItem (url,width,height,imageStyle) {
	let gif = "data:image/gif;base64,R0lGODlhAQABAIAAAP///////yH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="; 
	return (<img className={imageStyle} src={url} height={height} width={width} />);
    }
    
    makeEpgImg (epgid,width,height,style) {
	var url = this.epgImgUrl(epgid,width,height);
	return this.makeImgItem(url,width,height,style);
    }

    logoURL (chanid) {
	//return "https://" + globalServerName + "/logos/" + chanid + ".png";
	return "/logos/" + chanid + ".png";
    }


    showSeriesModal (entry) {
	// We'll also need to grab the series settings from the database for this object
	this.setState({showSeriesModal: true});
    }

    delVideo(videoObject) {
	//console.log("Delete pressed");
	if (videoObject.recorded || videoObject.progid) {
	    var reallydelete = confirm("Do you really want to cancel the recording of \"" + videoObject.title + "\"?");
	    if (reallydelete == true) {
		this.setState({alert: true,
			       alertTitle: 'Recording Status',
			       alertMessage: "Canceling...",
		});
		BTServer.sendCommand('delrechls',[videoObject.recorded],(results) => {
		
		    if (results.result == "OK") {
			videoObject.recorded = 0;
			
			this.setState({alert: false,
				       alertTitle: '',
				       alertMessage: '',
				       //videoObj: {}
			    
			});
			//this.props.refresh();
			//this.props.onHide();
		    
		    }
		});
	    }
	}
    }

    recVideo(videoObject) {
	if (videoObject.progid) {
	    this.setState({alert: true,
			   alertTitle: 'Recording Status',
			   alertMessage: "Scheduling...",
	    });

		
	    BTServer.sendCommand('setrec',[videoObject.progid],(results) => {
		
		if (results.result == "OK") {
		    videoObject.recorded = 1;
		    this.setState({alert: true,
				   alertTitle: 'Recording Status',
				   alertMessage: results.resdesc,
				   videoObj: videoObject
		    });
		    
		} else {
		    this.setState({alert: true,
				   alertTitle: "Recording Failed",
				   alertMessage: results.resdesc,
		    });
		}
	    });
	}
    }


    render() {

	var entry = this.state.videoObj || this.props.videoObject;

        var startTime = new Date(entry.gmtepochstart * 1000);
        var stopTime = new Date(entry.gmtepochend * 1000);
	var nowTime = new Date();
	var rating = (entry.rating || entry.mpaa);
	var ratingsURL = "/ratings/ratingspng/" + rating + ".png";
	var logoURL = this.logoURL(entry.guideid);

	var ratingWidth = 30;
        if (rating === "PG-13") { ratingWidth = 81; }
        if (rating === "PG") { ratingWidth = 50; }

        var img = this.makeEpgImg(entry.epgid,200,300,"square");

	var ratingimg = rating ? ( <img src={ratingsURL} style={{ height: 30, width: ratingWidth, backgroundColor: '#000', borderRadius: 3}}  />) : "";
	var delbtn = (  
	    <Button bsStyle="warning" style={{  textAlign: "center", width: 80, padding: 4}} onClick={() => this.delVideo(entry)}>DELETE</Button> 
	);

	var recbtn = (  
	    <Button bsStyle="danger" style={{  textAlign: "center", width: 80, padding: 4}} onClick={() => this.recVideo(entry)}>RECORD</Button> 
	)
	var cancelbtn = (  
	    <Button bsStyle="warning" style={{  textAlign: "center", width: 80, padding: 4}} onClick={() => this.delVideo(entry)}>CANCEL</Button> 
	);

	var recsrsbtn = (  
	    <Button bsStyle="danger" style={{  textAlign: "center", width: 140, padding: 4}} active={this.state.showSeriesModal} onClick={() => this.showSeriesModal(entry)}>RECORD SERIES</Button> 
	);


        var recbtndisplay;

	if (this.props.diskQuota && this.props.diskQuota.assigned && this.props.diskQuota.used) {
	    if ((this.props.diskQuota.used/this.props.diskQuota.assigned) > 0.99) {
		recbtn = (<Button bsTyle="default" disabled style={{  textAlign: "center", width: 170, padding: 4}}>NO SPACE TO RECORD</Button>);
	    }
	}


	var foo;

	//  =  {entry.recorded ? "Recorded on " + entry.cleandate : recbtn }	    

	if (entry.recorded && stopTime.valueOf() > nowTime.valueOf() ) {  // Show will end after now; give option to cancel recording
	    recbtndisplay = (<Text>{cancelbtn} Scheduled for {entry.cleandate}</Text>);
	} else if (entry.recorded) {
	    if (entry.candelete === "true") {
		recbtndisplay = (<Text>{delbtn} Recorded on {entry.cleandate}</Text>);
	    } else {
		recbtndisplay = (<Text>Recorded on {entry.cleandate}</Text>);
	    }
	} else {
	    if (entry.epgid.match(/^EP/)) { 
		recbtndisplay =  (<Text>{recbtn} &nbsp; &nbsp; {recsrsbtn}</Text>);
	    } else {
 		recbtndisplay = (<Text>{recbtn}</Text>);
	    }
	}



	    
	    return (
		
	       <View style={{padding: "10px"}}>		
		
	    <Grid fluid={true} style={{width: "100%"}}>
	    <Row> 
	    <Col md={8}>

	    <Row><Col md={12}>
	    <h1 style={{ display: "inline", verticalAlign: "middle" }}>{entry.title}</h1>
	    </Col></Row>
	    <Row><Col md={12} style={{paddingTop: "5px"}}>
	    { ratingimg }  &nbsp; &nbsp; 
	    <h4 style={{ display: "inline", verticalAlign: "middle"}}>{entry.duration} min. &nbsp; {entry.category} &nbsp; {entry.year} &nbsp; {entry.recorded ? "" :  startTime.timeNow() + "-" + stopTime.timeNow() }</h4>
	    </Col></Row>

	    </Col>

	    <Col xsHidden sm={4} md={4} style={{textAlign: "right", verticalAlign: "middle"}}>
	    <img src={logoURL} className="logo-shadow" style={{ maxWidth: "120px", maxHeight: "80px" }} />
	    </Col>
	    </Row>

	    

	    <hr />

	    <Row><Col md={8}>
	    {entry.subtitle ? <span style={{ fontSize: "24px"}}> {entry.subtitle} </span> : "" }

	    <p>{entry.desc}</p>

	    <View>{ recbtndisplay }</View>
	    <View>{this.state.alert ? this.state.alertTitle + ": " + this.state.alertMessage : ""}</View>

	    { this.state.showSeriesModal ? <Series videoObject={this.state.videoObj}></Series> : <View /> }
	    <View></View>

	    </Col>

	    <Col xsHidden sm={4} md={4} style={{textAlign: "right"}}>
	    { img }
	    </Col>
	    </Row>


	    </Grid>
		
		</View>
	
	    );

	    /* return ( 
	       <View style={{padding: "10px"}}>
	       <Col xs={4}><Row>{ img }</Row></Col>
	       <Col xs={8}>
	       <Row style={{fontSize: "18px", fontWeight: "bold"}}>{entry.title}</Row>
	       <Row>{entry.subtitle}</Row>
	       <Row>&nbsp;</Row>
	       <Row>{startTime.timeNow()} - {stopTime.timeNow()} &nbsp; &nbsp; { ratingimg } &nbsp; &nbsp; { recbtn }</Row>
	       <Row>&nbsp;</Row>
	       <Row>




	       </Row>
	       <Row>{entry.description}</Row>
	       </Col>
	       </View>
	       );

	     */


    }    


}

module.exports = LockupLg;



Date.prototype.timeNow = function(){
    return ((this.getHours() === 0 ? 12 : ((this.getHours()>12) ? (this.getHours()-12) : this.getHours()))
            +":"
	  + ((this.getMinutes() < 10)?"0":"") + this.getMinutes()
	    //+":"                                                                                                                                                                                                   \
            
            //+ ((this.getSeconds() < 10)?"0":"") + this.getSeconds()                                                                                                                                                \
            
	    +" "
	  + ((this.getHours()>11) ? ('PM'):'AM')
    );
};

Date.prototype.timeLittle = function(){
    return ((this.getHours() === 0 ? 12 : ((this.getHours()>12) ? (this.getHours()-12) : this.getHours()))
	    +":"
	  + ((this.getMinutes() < 10)?"0":"") + this.getMinutes()
            //+":"                                                                                                                                                                                                   \
            
	    //+ ((this.getSeconds() < 10)?"0":"") + this.getSeconds()                                                                                                                                                \
            
            +" "
          + ((this.getHours()>11) ? ('p.m.'):'a.m.')
    );
};

Date.prototype.timeNoLetters = function(){
    return ((this.getHours() === 0 ? 12 : ((this.getHours()>12) ? (this.getHours()-12) : this.getHours()))
            +":"
          + ((this.getMinutes() < 10)?"0":"") + this.getMinutes()
            //+":"                                                                                                                                                                                                   \
            
            //+":"                                                                                                                                                                                                   \
            
            //+ ((this.getSeconds() < 10)?"0":"") + this.getSeconds()                                                                                                                                                \
            
    );
};

