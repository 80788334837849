'use strict';

var React = require('react');
var ReactDOM = require('react-dom');

var View = require('./View');
var Text = require('./Text');
var Lockup = require('./Lockup');
var LargeVideoModal = require('./LargeVideoModal');
var MgRecordings = require('./MgRecordings');
var MgSeries = require('./MgSeries');
var MgScheduled = require('./MgScheduled');
//var TimerMixin = require('react-timer-mixin');
var {Grid, Row, Col, Button, ButtonGroup, Modal, Nav, Navbar, NavItem, MenuItem, NavDropdown, ProgressBar} = require('react-bootstrap');

var globalServerName = 'tv.9st.one';

import BTServer from './BTServer';



var {
    Component
} = React;


class Manage extends Component {

    componentDidMount() {
//	console.log("Manage mount fired, propsstate: " + this.props.selected );
	//if (this.props.selected) { this.fetchFeed();  }

    }

    constructor(props) {
	super(props);
	
	this.state = {
	    selectedView: "recordings"
	};


    }


    showView(viewname) {
	this.setState({ selectedView: viewname });
    }


    render() {

	var selectedView = this.state.selectedView;
	var shownView = <View />;

	if (selectedView === "recordings") {
	    shownView = <MgRecordings selected={this.props.selected} />;
	} else if (selectedView === "series") {
	    shownView = <MgSeries selected={this.props.selected} />;
	} else if (selectedView === "scheduled") {
	    shownView = <MgScheduled selected={this.props.selected} />;
	}
	
	return (
	    <div style={{paddingBottom: "10px", paddingTop: "10px"}}>


	      <View style={{padding: 0, margin: 0}}>	

	    <Navbar  browse={true} fluid={true}>
	    <Nav>
	    <NavItem eventKey="recordings" onClick={this.showView.bind(this,'recordings')} active={selectedView === "recordings"}>Recordings</NavItem>
	    <NavItem eventKey="series" onClick={this.showView.bind(this,'series')} active={selectedView === "series"}>Series Schedules</NavItem>
	    <NavItem eventKey="scheduled" onClick={this.showView.bind(this,'scheduled')} active={selectedView === "scheduled"}>Upcoming</NavItem>
	    </Nav>
	    </Navbar>

	    { shownView }

	    </View>

	    </div>

	);


    }

}

module.exports = Manage;
