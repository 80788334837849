'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
var Bootstrap = require('react-bootstrap');

//var Login = require('./Login');
//var AuthService = require('./AuthService');
var IPTV = require('./IPTV');

ReactDOM.render(
		<IPTV />,
		document.getElementById('root')
		);
