'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
var BTServer = require('./BTServer');
var View = require('./View');
var Text = require('./Text');
//var ListView = require('react-list-view');

var Ps = require('perfect-scrollbar');

var Lockup = require('./Lockup');

//var createFragment = require('react-addons-create-fragment');
var TimerMixin = require('react-timer-mixin');

var { Grid, Row, Col, Modal } = require('react-bootstrap');

var LargeVideoModal = require('./LargeVideoModal');

import Video from './Video';

var {
    Component
} = React;



const featuredstack = { "Now Showing on Local Channels" : { "key": 1, "command" : "qtnowsearch", "arguments" : [ "featured|1", "chanrange|21,41,51,71,111,131" ] , "orientation" : "l" },
                   "9Prime" : { "key": 2, "command" : "getrec"     , "arguments" : [ "primetime", "30" ] , "orientation" : "l" },
                   "Last Few Hours" : {  "key": 3, "command" : "getrec"     , "arguments" : [ "24hour",    "60" ] , "orientation" : "l" },
                   "Movies On Air"     : {  "key": 4, "command" : "qtnowsearch", "arguments" : [ "featured|1", "movies|1"]  , "orientation" : "p" },
                   "Sports"     : {  "key": 5, "command" : "qtnowsearch", "arguments" : [ "featured|1", "genre|sport"] , "orientation" : "l" },
                   "Kids Live"   : {  "key": 6, "command" : "qtnowsearch", "arguments" : [ "featured|1", "genre|child"] , "orientation" : "l" },
                   "My Newest Recordings" : {  "key": 7, "command" : "getrec"     , "arguments" : [ "hlsonly",    "30" ] , "orientation" : "p" },
};




class Featured extends Component {

    constructor(props) {
	super(props);

        var localObj = {};
	var localViews = [];

        for (var feature in featuredstack) {
            var localfeature = featuredstack[feature];
            localObj[feature] = [];
            localfeature.title = feature;
            localViews.push(localfeature);
        }

	this.scrollers = {}


	this.state = {
	    views: localViews,
	    viewData: localObj,
	    showModal: false,
	    showPlayer: false,
	    videoobj: {},
	    modalVidObj: {},
	    modalVidSection: {},
	    diskQuota: {},

	}




    }


    componentDidMount() {
	if (this.props.selected) { this.buildFeatured(); }
	TimerMixin.setInterval.call(this, () => { this.checkFeaturedLive(this.state.viewData) }, 15000);
	TimerMixin.setInterval.call(this, () => { this.checkFeaturedRec(this.state.viewData) }, 600000);
	window.addEventListener('resize',this.handleResize.bind(this));
    }


    componentWillUnmount() {
	TimerMixin.componentWillUnmount.call(this);
	window.removeEventListener('resize',this.handleResize);
    }


    buildFeatured()  {
	for (var feature in featuredstack) {
	    this.reBuildFeature(feature);
	}
    }

    reBuildFeature(feature) {

	var localresults = {};
	let myfeature = feature;
	
	var localfeature = featuredstack[myfeature];
	let mycommand = localfeature.command;
	let myarguments = localfeature.arguments;
	
	var localObj = this.state.viewData;
	var self = this;
	
	BTServer.sendCommand(mycommand, myarguments, (localresults) => {
	    //console.log("Reloading " + feature);	    
	    if (localresults.result == "OK") {
		localObj[myfeature] = localresults.payload;
		self.setState({viewData: localObj}); 
		if (localresults.quota) {
		    self.setState({diskQuota: localresults.quota});
		}
		
	    }
	});
    }
    
    checkFeaturedLive(mydata) {
	for (var feature in featuredstack) {
	    this.checkFeatureLive(feature,mydata);
	}
    }

    checkFeaturedRec(mydata) {
	for (var feature in featuredstack) {
	    this.checkFeatureRec(feature,mydata);
	}
    }
    
    checkFeatureLive(feature,indata) {
	var reload = false;
	let myfeature = feature;
	var mydata = indata[myfeature].slice();
	var date = new Date();
	var gmtepoch = parseInt(date.getTime()/1000);

	var localfeature = featuredstack[myfeature];
	let mycommand = localfeature.command;
	if (mycommand.match(/getrec/)) {
	    return;
	}

	//console.log("Checking feature " + myfeature + "...");
	for (var j = 0; j < mydata.length; j++ ) {
	    if (mydata[j].gmtepochend < gmtepoch && mydata[j].now) {
		//console.log("Need to reload "+ myfeature);
		reload = true; 
            }
	}
	if (reload) { this.reBuildFeature(feature); }
    }

    checkFeatureRec(feature,indata) {
	var reload = false;
	let myfeature = feature;
	var localfeature = featuredstack[myfeature];
	let mycommand = localfeature.command;
	if (mycommand.match(/getrec/)) {
	    this.reBuildFeature(feature); 
	}
    }
    


    close() {
	this.setState({showModal: false});
	this.setState({modalVidObj: {} });
	this.setState({modalVidSection: ''});
    }


    showVideo(videoObject,feature) {
	//console.log("Clicked " + videoObject.title  + " " + feature);
	this.setState({modalVidObj: videoObject});
	this.setState({showModal: true});
	this.setState({modalVidSection: feature});
    }

    closePlayer() {
	this.setState({showPlayer: false});
	this.setState({videoobj: {}});
    }

    playVideo(videoObject) {
	this.setState({showModal: false});
	this.setState({showPlayer: true});
	this.setState({videoobj: videoObject});
	this.setState({modalVidObj: {}});
	this.setState({modalVidSection: ''});
    }

    handleResize(event) {
//	//console.log("resizing");
	for (var title in this.scrollers) {
//	    //console.log("resizing " + node);
	    var node = this.scrollers[title];
	    var opacityLeft = (node.scrollLeft > 100 ? 100 : node.scrollLeft) * 0.01;
	    var rightScroll = (node.scrollWidth - node.offsetWidth) - node.scrollLeft;
	    var opacityRight = (rightScroll > 100  ? 100 : rightScroll) * 0.01;
	    if (node.scrollWidth === node.offsetWidth) { opacityRight = 0; }
	    node.previousElementSibling.style.opacity = opacityLeft;
	    node.nextElementSibling.style.opacity = opacityRight;
	}
    }

    handleScrollFade(event) {
//	//console.log("Event: " + event);
	var node = event.currentTarget;
	var opacityLeft = (node.scrollLeft > 100 ? 100 : node.scrollLeft) * 0.01;
	var rightScroll = (node.scrollWidth - node.offsetWidth) - node.scrollLeft;
	var opacityRight = (rightScroll > 100  ? 100 : rightScroll) * 0.01;
	if (node.scrollWidth === node.offsetWidth) { opacityRight = 0; }
	if (opacityLeft == 0)  { node.previousElementSibling.style.display = 'none' } else { node.previousElementSibling.style.display = 'inline-block' }
	if (opacityRight == 0) { node.nextElementSibling.style.display = 'none' } else { node.nextElementSibling.style.display = 'inline-block' }
	node.previousElementSibling.style.opacity = opacityLeft;
	node.nextElementSibling.style.opacity = opacityRight;
    }

    updateScroll(title,value,vect) {

	var xVect = /\b(left|lt|-1)\b/i.test( vect ) ? -1 :  /\b(right|rt|1)\b/i.test( vect ) ? 1 : 0;

//	//console.log("Title: " + title + " Value: " + value + " Direction: " + xVect);

	var node = this.scrollers[title];
	clearInterval(node.divScrollTimer);
//	//console.log("scrollLeft: " + node.scrollLeft + " scrollWidth:" + node.scrollWidth + " offsetWidth:" + node.offsetWidth);

	//value is a percentage of offsetWidth
	value = node.offsetWidth * (value * 0.01); 


	var xTravel = node.scrollWidth - node.offsetWidth;
	var xTarget = ( xVect > 0 ? Math.min( node.scrollLeft + value, xTravel )
		: Math.max( 0, node.scrollLeft - value ) ) << 0 ;

	// save this state so we can hide the scroll arrows

	var self = this;
	node.divScrollTimer = setInterval( function() { self.divscroll(node,xTarget,xVect); } , 40 );
	//node.preventDefault();

    }

    divscroll(node,xTarget,vect)  {
	//var xAvailTravel = node.scrollWidth - node.scrollLeft - node.offsetWidth // not used
//	//console.log("divscroll called");
	var xDone = false
	//var xTravel = node.scrollWidth + ( node.scrollWidth > node.offsetWidth ? 0 : 0 ) - node.offsetWidth;

//	//console.log("xTarget: "+ xTarget);

	if( xTarget !== node.scrollLeft ) {
	    node.scrollLeft += vect *  Math.max( 1, Math.abs( node.scrollLeft - xTarget ) / 2 );
//	    //console.log("scrollLeft = " + node.scrollLeft);
	}
	else
	    xDone = true;
	
	if( xDone )
	    clearInterval( node.divScrollTimer );
    }
	


    render() {

	var finalviews = {};


	for (var feature in featuredstack) {
	    finalviews[feature] = [];
	    for (var i in this.state.viewData[feature]) {
		var myobj = this.state.viewData[feature][i];
		
		finalviews[feature].push(<Lockup style={{height: "199px" }} entry={myobj}  key={myobj.progid || myobj.recorded} clicker={this.showVideo.bind(this,myobj,feature)} orientation={featuredstack[feature].orientation}>{myobj.title}</Lockup>);

	    }
	}
	
	var videoObj = this.state.videoobj || {};


	return (<div style={{paddingBottom: "10px"}}>

	    { this.state.views.map((eachView) => {
		// ref={ function(element) { Ps.initialize(element,{ supressScrollY: true  })}}
		// or try 
		// ref={Ps.initialize}
		
		
		var scrolledView = (<div style={{position: "relative"}}>
		    
		    <button className="swArrowLeft" onMouseDown={() => this.updateScroll(eachView.title,75,'left')}><span className="glyphicon glyphicon-chevron-left"></span></button>
		    <div  className="scrollwidth" ref={(ref) =>  { this.scrollers[eachView.title] = ref}}  onLoad={this.handleScrollFade} onScroll={this.handleScrollFade} style={{paddingLeft: "10px", paddingRight: "10px", position: "relative", }}>
	                    { finalviews[eachView.title] }
		    </div>
		    <button className="swArrowRight" onMouseDown={() => this.updateScroll(eachView.title,75,'right')}><span className="glyphicon glyphicon-chevron-right"></span></button>
		</div>);


		//Ps.initialize(scrolledView);
		
		return (<View key={eachView.key} style={{padding: "0px"}} > 
		    { <h3 style={{fontWeight: "200", paddingLeft: "10px"}}>{eachView.title}</h3> }  
		    {scrolledView}
		    </View>); 
		}) }

	    <LargeVideoModal diskQuota={this.state.diskQuota}  show={this.state.showModal} onHide={this.close.bind(this)} videoObject={this.state.modalVidObj} watch={this.playVideo.bind(this,this.state.modalVidObj)} refresh={this.reBuildFeature.bind(this,this.state.modalVidSection)}  >
	    </LargeVideoModal>

	    <Modal keyboard={true} bsClass="videoModal modal" dialogClassName="videoPlayerModal" show={this.state.showPlayer} onHide={this.closePlayer.bind(this)} style={{width: "100%", height: "auto", position: "absolute", top: 0, left: 0, verticalAlign: "middle"}}>
	    <Modal.Header id="videomodal" closeButton><Modal.Title>{videoObj.title} - {videoObj.channame}</Modal.Title></Modal.Header>
	    <Modal.Body>
	    <Video key={videoObj.progid} title="Video" url={videoObj.hlsurl} open={this.state.showPlayer} />
	    </Modal.Body>
	    </Modal>

	    </div>);


	
    }







}



module.exports = Featured;