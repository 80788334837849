'use strict';

var React = require('react');
var ReactDOM = require('react-dom');

var View = require('./View');
var Text = require('./Text');

import LockupRow from './LockupRow';
import LockupLg from './LockupLg';
import BTServer from './BTServer';
import Video from './Video';

var globalServerName = 'tv.9st.one';

var TimerMixin = require('react-timer-mixin');

var { Grid, Row, Col } = require('react-bootstrap');


var {
    Component
} = React;

const onReport = ({eventName}) => {
    //console.log(eventName);
};



class NowShowing extends Component {

    componentDidMount() {
	if (this.props.selected) { this.fetchFeed(); }
	TimerMixin.setInterval.call(this, () => { this.updateGuide(this.state.dataSourceRaw); }, 15000)
    }

    componentWillUnmount() {
	TimerMixin.componentWillUnmount.call(this);
    }


    constructor(props) {
	super(props);

	this.state = {
	    video: {},
	    dataSourceRaw: [],
	    showProgress: true,
	    diskQuota: {},
	}

    }


    fetchFeed(){
        BTServer.sendCommand('qtnow',[],(results) => {
            //console.log("getrec returned");                                                                                                                                                                             
            //console.log("results.result = " + results.result)                                                                                                                                                           
            if (results.result == "OK") {
                this.setState({dataSourceRaw: results.payload,
                               showProgress: false
                              });
		if (results.quota) {
		    this.setState({diskQuota: results.quota});
		}
            }
        });

    }

    updateGuide(indata) {

        var mydata = indata.slice();
        var date = new Date();
        var gmtepoch = parseInt(date.getTime()/1000);
        var chanlist = "0"; // Initializer only; there is no channel 0
        //console.log("Checking guide...");

        // add expired programs to the array of chanrange                                                                                                                                                                 
        //console.log("mydata.length: " + mydata.length);
        for (var j = 0; j< mydata.length; j++) {
            if (mydata[j].gmtepochend < gmtepoch) {
                chanlist = chanlist + "," + mydata[j].channum;
            }
        }
        if (chanlist !== "0") {
            //console.log("Updating program info for channels " + chanlist);
            BTServer.sendCommand("qtnowsearch",[ "chanrange|" + chanlist, "upnext|1"],(updatedData) => {

                if (updatedData.result == "OK") {
                var updateInfo = updatedData.payload;
                for (var j = 0; j< mydata.length; j++) {
                    for (var u = 0; u < updateInfo.length; u++) {
                        if (updateInfo[u].guideid === mydata[j].guideid) {
                            mydata[j] = updateInfo[u];

                            //console.log("Replaced program info for channel " + mydata[j].channum + " " + mydata[j].callsign + " : " + mydata[j].title);


                        }
                    }
                }
                this.setState({dataSourceRaw: mydata });
                }
            }

            );
        }
    }

    playShow(entry) {
	//console.log("Chose " + entry.title + " to watch");
	this.setState({video: entry});
    }


    render() {
	    //{ this.state.dataSourceRaw.map((eachShow) => { <LockupRow entry={eachShow} />  })}

	var videoObj = this.state.video || {};

	return (
	    <View style={{color: "white"}}>
	    <Grid style={{border: "0px solid red", height: "90vh"}} fluid={true}>
	    <Row style={{border: "0px dashed pink", height: "100%", padding: "10px"}}>


	    <Col xs={12} sm={12} md={8}>

	      <Row style={{padding: "10px" }}>
		<View className="tvboxouter">
		<View className="tvboxinner">
		<View style={{padding: "20px"}}>
		<Video key={videoObj.progid} title="Video" url={videoObj.hlsurl} />
		</View>
		</View>
		</View>
		</Row>

	    { this.state.video.title ? <div style={{ maxHeight: "370px"}}><LockupLg diskQuota={this.state.diskQuota} videoObject={videoObj}>{videoObj.title}</LockupLg></div> : "" }

	    </Col>


	    <Col xs={12} sm={12} md={4} className="col-centered" style={{height: "100%", overflowX:"none", overflowY:"scroll", border: "1px solid #aaa", maxWidth: "80%"}}>

	    { this.state.dataSourceRaw.map((eachShow) => { return  (<div key={eachShow.progid} onClick={()=>this.playShow(eachShow)} ><LockupRow entry={eachShow} >{eachShow.title}</LockupRow></div>)   }) }

	    </Col>

	    </Row>
	    </Grid>
	    </View>
	);
	
    }


}

module.exports = NowShowing;