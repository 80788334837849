

'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
var View = require('./View');
var Text = require('./Text');
var BTServer = require('./BTServer');
var Series = require('./Series');
var { Button, Grid, Row, Col, Modal } = require('react-bootstrap');

//import Video from './Video';

var {
    Component
} = React;


var globalServerName = "tv.9st.one";

class LargeVideoModal extends Component {

    componentDidMount() {

    }

    constructor(props) {
	super(props);

	this.state = {
	    alert: false,
	    alertStatus: '',
	    alertMessage: '',
	    videoObj: props.videoObject,
	    detailJson: {},
	}

    }

    componentWillReceiveProps(newProps) {
	this.setState({videoObj: newProps.videoObject});
	if (newProps.videoObject && newProps.videoObject.epgid) {

	    this.setState({alert: false,
			   alertTitle: '',
			   alertMessage: '',
			   showSeriesModal: false,
			   seriesVidObj: newProps.videoObject,
	    });
	    
	    
	    //if (newProps.videoObject.longDescription.length > 10) {
	    //this.setState({detailJson: { longDescription: newProps.videoObject.longDescription }});
	//} else {
		this.setState({detailJson: {} });
		this.tmsfetch("/pd?s=" + newProps.videoObject.epgid);
	//}
	}
    }

    tmsfetch(queryContent) {
	//var finalURL = "https://" + globalServerName + "/tmsdata" + queryContent;
	var finalURL = "/tmsdata" + queryContent;
	var self = this;
	console.log("Checking for tms data...");
	BTServer.sendDataQuery({url: finalURL, headers: { 'Accept':'application/json' }}, (results) => {
	    
	    //console.log("REsults: " + results.results.longDescription);
	    if (results.results) {
		self.setState({detailJson: results.results });
	    }
	});
    }

    delVideo(videoObject) {
	//console.log("Delete pressed");
	if (videoObject.recorded || videoObject.progid) {
	    var reallydelete = confirm("Do you really want to delete \"" + videoObject.title + "\"?");
	    if (reallydelete == true) {
		this.setState({alert: true,
			       alertTitle: 'Recording Status',
			       alertMessage: "Canceling...",
		});
		BTServer.sendCommand('delrechls',[videoObject.recorded],(results) => {
		
		    if (results.result == "OK") {
			videoObject.recorded = 0;
			
			this.setState({alert: false,
				       alertTitle: '',
				       alertMessage: '',
				       videoObj: {}
			    
			});
			this.props.refresh();
			this.props.onHide();
		    
		    }
		});
	    }
	}
    }

    recVideo(videoObject) {
	if (videoObject.progid) {
	    this.setState({alert: true,
			   alertTitle: 'Recording Status',
			   alertMessage: "Scheduling...",
	    });

		
	    BTServer.sendCommand('setrec',[videoObject.progid],(results) => {
		
		if (results.result == "OK") {
		    videoObject.recorded = 1;
		    this.setState({alert: true,
				   alertTitle: 'Recording Status',
				   alertMessage: results.resdesc,
				   videoObj: videoObject
		    });
		    
		} else {
		    this.setState({alert: true,
				   alertTitle: "Recording Failed",
				   alertMessage: results.resdesc,
		    });
		}
	    });
	}
    }


    showSeriesModal (entry) {
	// We'll also need to grab the series settings from the database for this object
	this.setState({showSeriesModal: true});
    }

    epgImgUrl (epgid,width,height) {
	var url = "?is=Md";
	if (height>width) { // portrait
	    url = url + "&as=2x3";
	} else { // landscape
	    url = url + "&as=4x3";
	} 
	// with text
	url = url + "&it=true&s=" + epgid;
	//var finalURL = "https://" + globalServerName + "/tmsdata/pi" + url;
	var finalURL = "/tmsdata/pi" + url;
	return finalURL;
    }

    makeImgItem (url,width,height,imageStyle) {
	let gif = "data:image/gif;base64,R0lGODlhAQABAIAAAP///////yH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="; 
	return (<img className={imageStyle} src={url} height={height} width={width} />);
    }
    
    makeEpgImg (epgid,width,height,style) {
	var url = this.epgImgUrl(epgid,width,height);
	return this.makeImgItem(url,width,height,style);
    }

    logoURL (chanid) {
	//return "https://" + globalServerName + "/logos/" + chanid + ".png";
	return "/logos/" + chanid + ".png";
    }


    render() {

	var entry = this.state.videoObj;
	
	if (entry.epgid === undefined) { // We dont' have an object yet 
	    return (<Modal />);
	}

        var startTime = new Date(entry.gmtepochstart * 1000);
        var stopTime = new Date(entry.gmtepochend * 1000);
	var nowTime = new Date();
	var rating = (entry.rating || entry.mpaa);
	var ratingsURL = "/ratings/ratingspng/" + rating + ".png";
	var logoURL = entry.guideid ? this.logoURL(entry.guideid) : '';

	var ratingWidth = 30;
        if (rating === "PG-13") { ratingWidth = 81; }
        if (rating === "PG") { ratingWidth = 50; }

        var img = this.makeEpgImg(entry.epgid,250,375,"square");

	var ratingimg = rating ? ( <img src={ratingsURL} style={{ height: 30, width: ratingWidth, backgroundColor: '#000', borderRadius: 3}}  />) : "";
	
	
	var recbtn = (  
	    <Button bsStyle="danger" style={{  textAlign: "center", width: 80, padding: 4}} onClick={() => this.recVideo(entry)}>RECORD</Button> 
	);

	var recsrsbtn = (  
	    <Button bsStyle="danger" style={{  textAlign: "center", width: 140, padding: 4}} active={this.state.showSeriesModal} onClick={() => this.showSeriesModal(entry)}>RECORD SERIES</Button> 
	);

	if (this.props.diskQuota && this.props.diskQuota.assigned && this.props.diskQuota.used) {
	    if ((this.props.diskQuota.used/this.props.diskQuota.assigned) > 0.99) {
		recbtn = (<Button bsTyle="default" disabled style={{  textAlign: "center", width: 170, padding: 4}}>NO SPACE TO RECORD</Button>);
		recsrsbtn = "";
	    }
	}



	// Need to push up a series recording modal to set or clear the options and delete the series recording settings, if desired
/*
	var recsrsbtn = (  
	    <Button bsStyle="danger" style={{  textAlign: "center", width: 140, padding: 4}} >RECORD SERIES</Button> 
	);
*/

	var delbtn = (  
	    <Button bsStyle="warning" style={{  textAlign: "center", width: 80, padding: 4}} onClick={() => this.delVideo(entry)}>DELETE</Button> 
	);

	var cancelbtn = (  
	    <Button bsStyle="warning" style={{  textAlign: "center", width: 80, padding: 4}} onClick={() => this.delVideo(entry)}>CANCEL</Button> 
	);
	    
	var playbtn = (  
	    <Button bsStyle="success" style={{  textAlign: "center", width: 80, padding: 4}} onClick={this.props.watch}>WATCH</Button> 
	);

        var recbtndisplay;
	var playbtndisplay;

	//  =  {entry.recorded ? "Recorded on " + entry.cleandate : recbtn }	    
	if (entry.recorded && stopTime.valueOf() > nowTime.valueOf() ) {  // Show will end after now; give option to cancel recording
	    recbtndisplay = (<Text>{cancelbtn} Scheduled for {entry.cleandate}</Text>);
	} else if (entry.recorded) {
	    if (entry.candelete === "true") {
		recbtndisplay = (<Text>{delbtn} Recorded on {entry.cleandate}</Text>);
	    } else {
		recbtndisplay = (<Text>Recorded on {entry.cleandate}</Text>);
	    }
	} else {
	    if (entry.epgid.match(/^EP/)) { 
		recbtndisplay =  (<Text>{recbtn} &nbsp; &nbsp; {recsrsbtn}</Text>);
	    } else {
 		recbtndisplay = (<Text>{recbtn}</Text>);
	    }
	}
	
	if (entry.now == "true" || (entry.recorded && startTime < nowTime.valueOf())) {
	    console.log("Display play button..." + entry.now);
	    playbtndisplay =  playbtn;
	} else { 
	    console.log("No display play button..." + entry.now);
	    playbtndisplay = startTime.toLocaleDateString() + " " + startTime.timeNow();
	}



	return (<Modal {...this.props} bsSize="large" aria-labelledby="contained-modal-tital-lg">
	    <Modal.Header closeButton>
	    <Modal.Title id="contained-modal-title-lg">{entry.title} - {entry.chanaff ? entry.channame + " (" + entry.chanaff + ")" : entry.channame } - {entry.cleandate} <small>({entry.channum})</small></Modal.Title>
	    </Modal.Header>
	    <Modal.Body>

	    <Grid fluid={true} style={{width: "90%"}}>
	    <Row> 
	    <Col md={8}>

	    <Row><Col md={12}>
	    <h1 style={{ display: "inline", verticalAlign: "middle" }}>{entry.title}</h1>
	    </Col></Row>
	    <Row><Col md={12} style={{paddingTop: "5px"}}>
	    { ratingimg }  &nbsp; &nbsp; 
	    <h4 style={{ display: "inline", verticalAlign: "middle"}}>{entry.duration} min. &nbsp; {entry.category} &nbsp; {entry.year} &nbsp; {entry.recorded ? "" :  startTime.timeNow() + "-" + stopTime.timeNow() }</h4>
	    </Col></Row>

	    </Col>

	    <Col md={4} style={{textAlign: "right", verticalAlign: "middle"}}>
	    <img src={logoURL} className="logo-shadow" style={{ maxWidth: "120px", maxHeight: "80px" }} />
	    </Col>
	    </Row>

	    

	    <hr />

	    <Row><Col md={8}>
	    {entry.subtitle ? <span style={{ fontSize: "24px"}}> {entry.subtitle} </span> : "" }

	    <p id="descriptionText">{ this.state.detailJson.longDescription ? this.state.detailJson.longDescription : entry.desc}</p>

	    <View>{playbtndisplay} &nbsp; &nbsp; {recbtndisplay}</View>
	    <View>{this.state.alert ? this.state.alertTitle + ": " + this.state.alertMessage : ""}</View>

	    { this.state.showSeriesModal ? <Series videoObject={this.state.videoObj}></Series> : <View /> }

	    </Col>
	    <Col md={4} style={{textAlign: "right"}}>
	    { img }
	    </Col>
	    </Row>


	    </Grid>

	    </Modal.Body>
	    </Modal>
	);


	}



}




module.exports = LargeVideoModal;


Date.prototype.timeNow = function(){
    return ((this.getHours() === 0 ? 12 : ((this.getHours()>12) ? (this.getHours()-12) : this.getHours()))
            +":"
	  + ((this.getMinutes() < 10)?"0":"") + this.getMinutes()
	    //+":"                                                                                                                                                                                                   \
            
            //+ ((this.getSeconds() < 10)?"0":"") + this.getSeconds()                                                                                                                                                \
            
	    +" "
	  + ((this.getHours()>11) ? ('PM'):'AM')
    );
};

Date.prototype.timeLittle = function(){
    return ((this.getHours() === 0 ? 12 : ((this.getHours()>12) ? (this.getHours()-12) : this.getHours()))
	    +":"
	  + ((this.getMinutes() < 10)?"0":"") + this.getMinutes()
            //+":"                                                                                                                                                                                                   \
            
	    //+ ((this.getSeconds() < 10)?"0":"") + this.getSeconds()                                                                                                                                                \
            
            +" "
          + ((this.getHours()>11) ? ('p.m.'):'a.m.')
    );
};

Date.prototype.timeNoLetters = function(){
    return ((this.getHours() === 0 ? 12 : ((this.getHours()>12) ? (this.getHours()-12) : this.getHours()))
            +":"
          + ((this.getMinutes() < 10)?"0":"") + this.getMinutes()
            //+":"                                                                                                                                                                                                   \
            
            //+":"                                                                                                                                                                                                   \
            
            //+ ((this.getSeconds() < 10)?"0":"") + this.getSeconds()                                                                                                                                                \
            
    );
};

