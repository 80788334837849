'use strict';
var React = require('react');
var ReactDOM = require('react-dom');

var View = require('./View');
var Text = require('./Text');
var Lockup = require('./Lockup');
var LargeVideoModal = require('./LargeVideoModal');
var TimerMixin = require('react-timer-mixin');
var {Grid, Row, Col, Button, ButtonGroup, Modal, Nav, Navbar, NavItem, MenuItem, NavDropdown, ProgressBar} = require('react-bootstrap');

//var ReactCSSTransitionGroup = require('react-addons-css-transition-group');

var globalServerName = 'tv.9st.one';

import BTServer from './BTServer';
import Video from './Video';


var {
    Component
} = React;



class Recordings extends Component {

    componentDidMount() {
//	//console.log("Recordings mount fired, propsstate: " + this.props.selected );
	if (this.props.selected) { this.fetchFeed();  }
    }



    constructor(props) {
	super(props);

	this.state = {
	    entry: {},
 	    firstLoad: true,
	    showModal: false,
	    showPlayer: false,
	    showProgress: true,
	    modalVidObj: {},
	    videoobj: {},
	    dataSetPrimes: [],
            dataSetPrimesResults: {},
	    dataSet24hour: [],
            dataSet24hourResults: {},
            dataSetMovies: [],
            dataSetMoviesResults: {},
            dataSetShowTitles: [],
            dataSetShowResults: {},
            dataSetGenres: [],
            dataSetGenresResults: {},

            dataSetRecent: {},
            dataSetRecentResults: [],

	    dataSourceSelected: [],
	    selectedData: 'recent',
	    selectedDataName: 'Recent Recordings',
	    selectedElements: [],

	    diskQuota: {},

//	    views: [],
//	    viewData: {}
	}
    }


    fetchFeed() {
	
//	 if (this.state.once) {  return; }

//	this.setState({once: true});

	var self = this;

	BTServer.sendCommand('getrec',['hlsonly'],(qresults) => {
	    
	    if (qresults.result === "OK") {
		//console.log("results.result 1 = " + qresults.result);  
		
		BTServer.sendCommand('getrec',['primetime'],(qresults2) => {
		    
		    if (qresults2.result === "OK") {
			//console.log("results.result 2 = " + qresults2.result);
			//console.log("Payload size: " + qresults.payload.length + " + " + qresults2.payload.length);


			BTServer.sendCommand('getrec',['24hour'],(qresults3) => {

			    if (qresults3.result === "OK") {
		    
			var results = {};
			var primeResults = {};
			var twentyfourResults = {};
			var dateResults = {};
			var titleResults = {};
			var showResults = {};
			
			var seen = {};

			var payload1 = qresults.payload;
			var payload2 = qresults2.payload;
			var payload3 = qresults3.payload;
			//var payload  = payload1.concat(payload2);
			var payload  = payload1;
			var recentNum = 30;
			
			var payload1slice = payload1.length > recentNum ? recentNum : payload1.length;

			// Do simple stuff off the bat
			var recentNumName = 'Last ' + recentNum;

			var recents =  [' '];
			var recentResults = {' ': payload1.slice(0,payload1slice) };

			if (self.state.firstLoad) {

			    self.setState({
				// All recordings 
				//dataSourceBEEPrime: this.state.dataSourceBEEPrime.cloneWithRows(payload2),   
				// Recent recordings   
				
				dataSetRecent: recents,
				dataSetRecentResults: recentResults,
				// manual version of chooseResults here; other stuff set initially
				selectedElements: recents,
				dataSourceSelected: recentResults,
				diskQuota: qresults.quota,
				firstLoad: false,
			    });
			}

			// Massage the information into different datasources   

			for (var x = 0; x < payload.length; x++) {
			    payload[x].listid = x;

			    // Make this an option -- hide or show shows above my max ratings 
			    //if (payload[x].ratingranktv > Settings.restrictions.maxTVShowRank || payload[x].ratingrankmv > Settings.restrictions.maxMovieRank) { continue; }
			    if (!payload[x].category || !payload[x].epgid || !payload[x].hlsurl) { continue; }
			    var type = "sh";
			    //if (seen[payload[x].epgid]) { continue; } else { seen[payload[x].epgid] = true; }   


			    if (payload[x].epgid.match(/MV/))  // we're a movie
				type = "mv";

			    // Genre sorting

			    if (!results[payload[x].category]) {
				//results[type][payload[x].category] = [];
				results[payload[x].category] = [];
			    }
			    results[payload[x].category].push(payload[x]);

			    // Date sorting.. already done 



			    var cleandate = payload[x].cleandate;
			    if (!dateResults[cleandate]) {
				dateResults[cleandate] = [];
			    }
			    dateResults[cleandate].push(payload[x]);

			    // At some point we'll want to remove "A" and "The" from the beginning 
			    //var titleFirst = payload[x].title;

			    // Group by first letter  
			    //var firstLetterOfTitle = payload[x].title;  


			    var firstLetter = payload[x].title.charAt(0).toUpperCase();
			    var firstLetterOfTitle;
			    if (firstLetter.match(/[A-D]/)) {
				firstLetterOfTitle = 'A-D';
			    } else if (firstLetter.match(/[E-I]/)) {
				firstLetterOfTitle = 'E-I';
			    } else if (firstLetter.match(/[J-N]/)) {
				firstLetterOfTitle = 'J-N';
			    } else if (firstLetter.match(/[O-U]/)) {
				firstLetterOfTitle = 'O-U';
			    } else if (firstLetter.match(/[V-Z]/)) {
				firstLetterOfTitle = 'V-Z';
			    }

			    if (type === "mv") {
				if (!titleResults[firstLetterOfTitle]) {
				    titleResults[firstLetterOfTitle] = [];
				}
				titleResults[firstLetterOfTitle].push(payload[x]);
			    } else {
				//var showTitle = payload[x].title;  
				//if (!showResults[showTitle]) {
				//    showResults[showTitle] = [];   
				//}    
				//showResults[showTitle].push(payload[x]);

				if (!showResults[firstLetterOfTitle]) {
				    showResults[firstLetterOfTitle] = [];
				}
				showResults[firstLetterOfTitle].push(payload[x]);
			    }
			}


			var primelength = payload2.length > 60 ? 60 : payload2.length;
			for (var x = 0; x <  primelength; x++) {
			    payload2[x].listid = x + payload1.length;

			    if (!payload2[x].category || !payload2[x].epgid || !payload2[x].hlsurl) { continue; }
			    var callsign = payload2[x].chanaff;
			    if (!primeResults[callsign]) {
				primeResults[callsign] = [];
			    }
			    primeResults[callsign].push(payload2[x]);

			}

			var twentyfourlength = payload3.length > 1000 ? 1000 : payload3.length;
			for (var x = 0; x <  twentyfourlength; x++) {
			    payload3[x].listid = x + payload1.length + payload2.length;

			    if (!payload3[x].category || !payload3[x].epgid || !payload3[x].hlsurl) { continue; }
			    let callsign = payload3[x].callsign;
			    if (!!payload3[x].chanaff) { callsign = payload3[x].chanaff; }

			    let progtitle = payload3[x].title.substring(0,1)

			    // This method sorts the shows by callsign
			    /*
			    if (!twentyfourResults[callsign]) {
				twentyfourResults[callsign] = [];
			    }
			    twentyfourResults[callsign].push(payload3[x]);
			    */
			    // Let's sort by first letter of TV Program name instead
			    if (!twentyfourResults[progtitle]) {
				twentyfourResults[progtitle] = [];
			    }
			    twentyfourResults[progtitle].push(payload3[x]);

			}


			// Add Prime time, sorted by date  
			var primes = Object.keys(primeResults);
			primes.sort();

			self.setState({ dataSetPrimes: primes, dataSetPrimesResults: primeResults });


			// Add 24Hour, sorted by date  
			var twentyfour = Object.keys(twentyfourResults);
			twentyfour.sort();

			self.setState({ dataSet24hour: twentyfour, dataSet24hourResults: twentyfourResults });

			// Sorted alphabetically 
			var titles = Object.keys(titleResults);
			titles.sort();

			self.setState({ dataSetMovies: titles, dataSetMoviesResults: titleResults });


			// Sorted alphabetically 


			var showTitles = Object.keys(showResults);
			showTitles.sort(function(a, b) {
			    if (a.toLowerCase() < b.toLowerCase()) return -1;
			    if (a.toLowerCase() > b.toLowerCase()) return 1;
			    return 0;
			});


			self.setState({ dataSetShowTitles: showTitles, dataSetShowResults: showResults });

			// Sorted by Genres 

			/*   
			var genres = Object.keys(results); 
			genres.sort(); 
			this.setState({ dataSetGenres: genres, dataSetGenresResults: results });   
			*/

			if (!self.state.firstLoad) {
			    self.showChosenResults()
			}

			self.setState({showProgress: false});

		    } // end results3 OK   
		} // end getrec 24hour 
		); // end BTSendCommand  

		    } // end results2 OK   
		} // end getrec primetime 
		); // end BTSendCommand  
	    } // end get results1 OK    
	} // end getrec  
	); // end BTSendCommand   
    } // end fetchfeed


    

    generateLockups(sectionTitle,id,sectionElements,sectionResults,sortElements,orientation,clicker) {


//        var gds = new ListView.DataSource({
//            rowHasChanged: (r1, r2) => r1 != r2
//        });

        var lockup = [];


        for (var m = 0; m < sectionElements.length; m++) {

            var x = sectionElements[m];
            if (x === "") { continue; }

            var entry = sectionResults[x];
            var count = 0;


            if (sortElements === 'title') {
                entry.sort(function(a, b) {
                    if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
                    if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
                    return 0;
                });
            } else if (sortElements === 'newdate' ) {
                entry.sort(function(a, b) {
                    if (a.recordingEnd < b.recordingEnd) return 1;
                    if (a.recordingEnd > b.recordingEnd) return -1;
                    return 0;
                });
            }


            var dataObj = entry;

            lockup[m] = (
		<View key={m} className="lockup">
		<h4 style={{fontWeight: "200", paddingLeft: "0px"}}>{x}</h4>
		<View key={id+m} style={{padding: 0, overflow: "hidden", margin: 0}}>
		{ dataObj.map((eachRecording) => {
		    return (<Lockup key={eachRecording.progid}
			clicker={()=>clicker(eachRecording)} 
			entry={eachRecording} 		    
			style={{padding: "10px", verticalAlign: "top", display: "inline-block"}}
			orientation={orientation || 'p'} >{eachRecording.title}</Lockup>)
		})}
		</View>
		</View>
            );


        }


	return lockup ;

    }

    chooseResults(dataTitle,dataElements,data) {
	this.setState({selectedDataName: dataTitle,
		       selectedElements: dataElements,
		       dataSourceSelected: data});
    }
    
    showChosenResults(selectedDataLocal) {
 	if (selectedDataLocal) {
	    this.setState({ selectedData: selectedDataLocal });
	} else {
	    selectedDataLocal = this.state.selectedData; 
	}
	if (selectedDataLocal === "recent") { this.chooseResults('Recent Recordings',this.state.dataSetRecent,this.state.dataSetRecentResults) } 
	else if (selectedDataLocal === "movies") { this.chooseResults('Movies',this.state.dataSetMovies,this.state.dataSetMoviesResults) } 
	else if (selectedDataLocal === "tvshows") { this.chooseResults('TV Shows',this.state.dataSetShowTitles,this.state.dataSetShowResults) }
	else if (selectedDataLocal === "prime") { this.chooseResults('9Prime',this.state.dataSetPrimes,this.state.dataSetPrimesResults) }
	else if (selectedDataLocal === "24hour") { this.chooseResults('Replay',this.state.dataSet24hour,this.state.dataSet24hourResults) };

    }


    close() {
	this.setState({ showModal: false });
	this.setState({modalVidObj: {} });
    }

    showVideo(videoObject) {
	//console.log("Clicked " + videoObject.title );
	this.setState({modalVidObj: videoObject});
	this.setState({showModal: true});
    }

    closePlayer() {
	this.setState({showPlayer: false});
	this.setState({videoobj: {}});
    }

    playVideo(videoObject) {
	this.setState({showModal: false});
	this.setState({showPlayer: true});
	this.setState({videoobj: videoObject});
	this.setState({modalVidObj: {}});
    }


    render() {

	// 15 Recent Recordings at the top

	// Movies (4 sections)

	// TV Shows (4 sections)


	var videoObj = this.state.videoobj || {};
	var selectedData = this.state.selectedData;
/*

*/


//	    { this.generateLockups("Movies",'movie',this.state.dataSetMovies,this.state.dataSetMoviesResults,'title','',this.showVideo.bind(this)) }
//            { this.generateLockups("TV Shows",'shows',this.state.dataSetShowTitles,this.state.dataSetShowResults,'newdate','l',this.showVideo.bind(this)) }


/*
 	    { this.state.dataSourceSelected.map((eachRecording) => {
		return (<Lockup 
		    key={eachRecording.recorded} 
		    clicker={this.showVideo.bind(this,eachRecording)} 
		    entry={eachRecording} 
		    style={{padding: "10px", verticalAlign: "top", display: "inline-block"}}
		    orientation="p" >{eachRecording.title}</Lockup>)
	    })}
*/
	var sortmethod = 'title';	
	if (selectedData === 'recent') { sortmethod = 'newdate'; }

	var self = this;


// 	    <NavItem eventKey="manage" active={selectedData === "manage"}>Manage</NavItem>
	

	var quotaPercentage = ((this.state.diskQuota.used / this.state.diskQuota.assigned) * 100).toFixed(0);
	var progressStyle = "success";
	if (quotaPercentage > 80) { progressStyle = "warning"; }
	if (quotaPercentage > 98) { progressStyle = "danger"; }

	return (
	    <div style={{paddingBottom: "10px", paddingTop: "10px"}}>


	    { this.state.showProgress ?  <h2 style={{padding: "10px"}}>Loading Recordings, Please Wait...</h2> :

	      <View style={{padding: 0, margin: 0}}>	

	    <Navbar  browse={true} fluid={true}>
	    <Nav>
	    <NavItem eventKey="recent" onClick={this.showChosenResults.bind(this,'recent')}  active={selectedData === "recent"}>My Recent</NavItem>
	    <NavItem eventKey="movies" onClick={this.showChosenResults.bind(this,'movies')} active={selectedData === "movies"}>My Movies</NavItem>
	    <NavItem eventKey="tvshows" onClick={this.showChosenResults.bind(this,'tvshows')} active={selectedData === "tvshows"}>My TV Shows</NavItem>
	    <NavItem eventKey="prime" onClick={this.showChosenResults.bind(this,'prime')} active={selectedData === "prime"}>Prime Time</NavItem>
	    <NavItem eventKey="24hour" onClick={this.showChosenResults.bind(this,'24hour')} active={selectedData === "24hour"}>Replay TV</NavItem>
	    </Nav>
	    </Navbar>


	<div style={{ paddingLeft: "10px", paddingRight: "10px"}}><ProgressBar  bsStyle={progressStyle} now={parseInt(quotaPercentage)} label={"Storage Used: " + quotaPercentage + '%'} /></div>



 	    <h3 style={{fontWeight: "200", paddingLeft: "10px", height: "20px"}}>{this.state.selectedDataName}</h3>
	    <View style={{paddingLeft: "10px", margin: 0, overflowY: "scroll", maxHeight: "calc(100vh - 204px)" }} >
	    { self.generateLockups(self.state.selectedDataName,selectedData,self.state.selectedElements,self.state.dataSourceSelected,sortmethod,'p',self.showVideo.bind(self))  }
	    </View>

		</View>

		}


	    <LargeVideoModal  show={this.state.showModal} onHide={this.close.bind(this)} videoObject={this.state.modalVidObj} watch={this.playVideo.bind(this,this.state.modalVidObj)} refresh={self.fetchFeed.bind(self)} >
	    </LargeVideoModal>

	    <Modal keyboard={true} bsClass="videoModal modal" dialogClassName="videoPlayerModal" show={this.state.showPlayer} onHide={this.closePlayer.bind(this)} style={{width: "100%", height: "auto", position: "absolute", top: 0, left: 0, verticalAlign: "middle"}}>
	    <Modal.Header id="videomodal" closeButton><Modal.Title>{videoObj.title}</Modal.Title></Modal.Header>
	    <Modal.Body>
	    <Video key={videoObj.progid} title="Video" url={videoObj.hlsurl} />
	    </Modal.Body>
	    </Modal>

	    
	    </div>
	);
	
    }


}

module.exports = Recordings;