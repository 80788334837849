'use strict';

var React = require('react');
var ReactDOM = require('react-dom');

var View = require('./View');
var Text = require('./Text');
//var Lockup = require('./Lockup');
//var LargeVideoModal = require('./LargeVideoModal');
//var TimerMixin = require('react-timer-mixin');
var {Grid, Row, Col, Button, ButtonGroup, Modal, Nav, Navbar, NavItem, MenuItem, NavDropdown, ProgressBar} = require('react-bootstrap');

var globalServerName = 'tv.9st.one';

import BTServer from './BTServer';


var {
    Component
} = React;


class MgScheduled extends Component {

    componentDidMount() {
//	//console.log("MgScheduled mount fired, propsstate: " + this.props.selected );
	//if (this.props.selected) { this.fetchFeed();  }

    }

    constructor(props) {
	super(props);
	
	this.state = {
	    dataSet: [],
	    diskQuota: { used: 0, assigned: 0 },

	};


    }

    componentDidMount() {
//	//console.log("MgRecordings mount fired, propsstate: " + this.props.selected );
	if (this.props.selected) { this.fetchRecordings();  }

    }


    fetchRecordings() {

	var self = this;

	BTServer.sendCommand('getrec',['scheduled'],(qresults) => {
	    
	    if (qresults.result === "OK") {
		//console.log("reloaded recording data");  
		
// Add stuff for (auto)refreshing page, etc.

		var results = {};
		var payload = qresults.payload;
		var quota = qresults.quota;

		self.setState({ 
		    dataSet: payload,
		    diskQuota: quota
		});
		
	
	    }

							    
	});							    

    }

    delVideo(videoObject) {
	//console.log("Delete pressed");
	if (videoObject.recorded || videoObject.progid) {
	    var reallydelete = confirm("Do you really want to cancel the recording of \"" + videoObject.title + "\"?");
	    if (reallydelete == true) {
		this.setState({alert: true,
			       alertTitle: 'Schedule Status',
			       alertMessage: "Canceling...",
		});
		BTServer.sendCommand('delrechls',[videoObject.recorded],(results) => {
		
		    if (results.result == "OK") {
			videoObject.recorded = 0;
			
			this.setState({alert: false,
				       alertTitle: '',
				       alertMessage: '',
				       videoObj: {}
			    
			});
			this.fetchRecordings();
		    }
		});
	    }
	}
    }


    renderRow(entry) {
	//console.log("Rendering row for " + entry.title);

	var delbtn = (  
	    <Button bsStyle="warning" style={{  textAlign: "center", width: 80, padding: 4}} onClick={() => this.delVideo(entry)}>CANCEL</Button> 
	);

	var nowTime = new Date();
        var startTime = new Date(entry.gmtepochstart * 1000);
        var stopTime = new Date(entry.gmtepochend * 1000);

        var oneday = 86400000;
	var dayDiff = ((nowTime.valueOf() - stopTime.valueOf()) / oneday) | 0;
        var dayDiff2 = nowTime.getDay() - startTime.getDay();
        //var dayName = startTime.toLocaleDateString();
	var timeName = startTime.toLocaleTimeString();
	timeName = timeName.replace(/:00 /," ");
	var dayName = startTime.toLocaleDateString();
	
	if (dayDiff == 0 && dayDiff2 == 0) { // today
	    var hourdiff = parseInt((startTime.valueOf() - nowTime.valueOf()) / 3600000);
	    if (hourdiff > 1) { 
		dayName = "In about " + hourdiff + " hours";
	    } else {
		var mindiff = startTime.getMinutes() - nowTime.getMinutes(); 
		dayName = "In " + mindiff + " minutes";
	    }
	} else if ((dayDiff2 == -1 || dayDiff2 == 6) && (stopTime.valueOf() - nowTime.valueOf() < 2 * oneday)) { dayName = "Tomorrow"; }

	return (
	    <tr key={entry.recorded}>
	    <td className="recordingTD" style={{ width: "250px" }}> {entry.title}<br /><small>{entry.subtitle}</small></td>
	    <td className="recordingTD" style={{ width: "150px"}}>  {dayName} <br /> {timeName}  </td>
	    <td className="recordingTD" style={{ width: "85px" }}>  {entry.candelete ? delbtn : ""}</td>
	    <td className="recordingTD" style={{ }}><small>{entry.description}</small></td>
	    </tr>);


	


    }




    render() {

	var quotaPercentage = ((this.state.diskQuota.used / this.state.diskQuota.assigned) * 100).toFixed(0);
	var dataSet = this.state.dataSet;
	var self = this;


	return (

	    <div style={{paddingBottom: "10px", paddingTop: "10px"}}>
	    
	    <div style={{ paddingLeft: "10px", paddingRight: "10px"}}>
	    
	    </div>
	    
	    <View style={{padding: 0, margin: 0}}>	
	    
	    <table className="recordingTable"><tbody>
	    <tr><th>Title</th><th>Date</th><th>Actions</th><th>Description</th></tr>
	    
	    { dataSet.map((entry) => this.renderRow(entry)) }
	    
	    </tbody>
	    </table>
	    </View>


	    </div>

	);

	}

}

module.exports = MgScheduled;
