'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
var BTServer = require('./BTServer');
var View = require('./View');
var Text = require('./Text');
var LogoLockup = require('./LogoLockup');
import LockupRow from './LockupRow';
import LockupLg from './LockupLg';
import Video from './Video';
var globalServerName = 'tv.9st.one';

var TimerMixin = require('react-timer-mixin');

var { Grid, Row, Col, Modal } = require('react-bootstrap');
var LargeVideoModal = require('./LargeVideoModal');



var {
    Component
} = React;

const onReport = ({eventName}) => {
    //console.log(eventName);
};




class Channels extends Component {

    componentDidMount() {
	if (this.props.selected) { this.loadChannels(); }
    }


    constructor(props) {
	super(props);

	this.state = {
	    video: {},
	    dataSourceChanList: [],
	    dataSourceChannel: [],
	    channel: { channame: "Channels" },
	    showProgress: true,
	    showModal: false,
	    showPlayer: false,
	    videoobj: {},
	    modalVidObj: {},

	}

    }

    loadChannels() {
	//console.log("Getting channels...");
	var self = this;
	this.setState({showProgress: true });
	BTServer.sendCommand("qtnowsmartsearch", [ "featured", "onlyfields|channum,callsign,guideid,channame" ],(results) => {
	    if (results.result == "OK") {
		//console.log("Got channels.");
		self.setState({dataSourceChanList: results.payload, showProgress: false});
	    }
	}
	)
    }

    loadChannel(channel) {
	var self = this;
	var channum = channel.channum;
	this.setState({dataSourceChannel: [], showProgress: true, channel: channel });
	BTServer.sendCommand("qtnowsmartsearch", [ "smart|" + channum, "limit|50" ],(results) => {
	    if (results.result == "OK") {
		//console.log("Got channel listing.");
		self.setState({dataSourceChannel: results.payload, showProgress: false});
	    }
	}
	)
    }
	    
    logoURL (chanid) {
	//return "https://" + globalServerName + "/logos/" + chanid + ".png";
	return "/logos/" + chanid + ".png";
    }


    close() {
	this.setState({showModal: false});
	this.setState({modalVidObj: {} });
    }
    showVideo(videoObject,feature) {
	//console.log("Clicked " + videoObject.title  + " " + feature);
	this.setState({modalVidObj: videoObject});
	this.setState({showModal: true});
    }

    closePlayer() {
	this.setState({showPlayer: false});
	this.setState({videoobj: {}});
    }

    playVideo(videoObject) {
	this.setState({showModal: false});
	this.setState({showPlayer: true});
	this.setState({videoobj: videoObject});
	this.setState({modalVidObj: {}});
    }


    render() {

	var textwidth = 100;

	var chanlist = this.state.dataSourceChanList.map((eachChannel) => { 
	    var img = this.logoURL(eachChannel.guideid);
	    return (<div className="wrapper" onClick={()=>this.loadChannel(eachChannel)} key={eachChannel.guideid}>
		          <div className="logocontainer" style={{maxWidth: textwidth}}><img src={img} className="logoimg logo-shadow" style={{maxHeight: 60, maxWidth: 80}} /></div>
		          <div style={{width: textwidth }} className="titleText"><span> {eachChannel.channame} </span></div>
		   </div>) 
	});


	var counter = 0;
	var todaydate = "";
	var channel = this.state.dataSourceChannel.map((eachShow) => { 
	    var header = "";
	    if (counter == 0 && eachShow.now) {
		header = (<h3>On Now - {this.state.channel.channame}</h3>);
	    } else if (todaydate !== eachShow.cleandate) { 
		todaydate = eachShow.cleandate;
		if (counter == 1)  { header = (<h3>Up Next</h3>); } else { header = (<h3>{todaydate}</h3>); }
	    } else { 
		if (counter == 2)  { header = (<h3>Later</h3>); } else { header = ""; }
	    }
	    counter++;
	    return  (<Grid key={eachShow.progid} onClick={() => this.showVideo(eachShow)}>{header}<LockupRow entry={eachShow} >{eachShow.title}</LockupRow></Grid>);
	})

	var videoObj = this.state.videoobj || {};


	return (<View>
	    <View className="scrollwidthchannels">{ chanlist }</View>

	    { this.state.showProgress ? (<h2 style={{fontWeight: "100"}}>Loading {this.state.channel.channame}...</h2>) : "" }
	    
	    <View className="col-centered" style={{height: "100%", overflowX:"none", overflowY:"scroll",  width: "100vw"}}>

	    { channel }

	    </View>



	    <LargeVideoModal  show={this.state.showModal} onHide={this.close.bind(this)} videoObject={this.state.modalVidObj} watch={this.playVideo.bind(this,this.state.modalVidObj)} >
	    </LargeVideoModal>

	    <Modal dialogClassName="videoPlayerModal" show={this.state.showPlayer} onHide={this.closePlayer.bind(this)} style={{width: "100%", height: "auto", position: "absolute", top: 0, left: 0, verticalAlign: "middle"}}>
	    <Modal.Header closeButton><Modal.Title>{videoObj.title}</Modal.Title></Modal.Header>
	    <Modal.Body>
	    <Video key={videoObj.progid} title="Video" url={videoObj.hlsurl} />
	    </Modal.Body>
	    </Modal>


	    </View>
	);
	
    }

/*
*/


}

module.exports = Channels;
