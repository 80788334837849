'use strict';

var React = require('react');
var ReactDOM = require('react-dom');

var View = require('./View');
var Text = require('./Text');
var Lockup = require('./Lockup');
var LargeVideoModal = require('./LargeVideoModal');
var TimerMixin = require('react-timer-mixin');
var { Grid, Row, Col, Button, ButtonGroup, Modal, Nav, Navbar, NavItem, MenuItem, NavDropdown, FormGroup, FormControl } = require('react-bootstrap');

var globalServerName = 'tv.9st.one';

import BTServer from './BTServer';
import Video from './Video';


var {
    Component
} = React;



class SearchView extends Component {

    componentDidMount() {


    }



    constructor(props) {
	super(props);

	this.state = {
	    showModal: false,
	    showPlayer: false,
	    modalVidObj: {},
	    videoobj: {},
	    results: [],
	    showProgress: true,
	    searchValue: '',
	    diskQuota: {},
	}

    }


//    handleChange(e) {
//	//console.log('Handling a change...');
//	this.setState({searchValue: e.target.value });
//    }

    fetchResults(e) {
	e.preventDefault();
	var searchTerm = e.target.children[0].children[0].value;
	if (searchTerm.length < 1) { return; }
	//console.log('submit pressed');
	var self = this;
	self.setState({showProgress: true });
	BTServer.sendCommand('qtnowsmartsearch',["smart|" + searchTerm,"limit|2000"],(request) => {
	    //console.log("request.result = " + request.result);
	    
	    var results = [];
	    
	    if (request.result == "OK") {
		//console.log("fetchResults returned with data");
		
		if (request.quota) {
		    self.setState({diskQuota: request.quota});
		}

		var payload = request.payload;
		if (payload.length > 0) {
		    for (var x = 0; x < payload.length; x++) {
			//console.log("Iterating over item " + x);
			var entry = payload[x];
			entry.listid = x;
			if (!entry.category || !entry.epgid || !entry.hlsurl) { continue; }
			//if (entry.category === "Adults only") { continue; } // hard coded override for now		    
			results.push(entry);
		    }
		    self.setState({ results: results});
		}
		self.setState({showProgress: false});
	    }
	});
    }

    close() {
	this.setState({ showModal: false });
	this.setState({modalVidObj: {} });
    }

    showVideo(videoObject) {
	//console.log("Clicked " + videoObject.title );
	this.setState({modalVidObj: videoObject});
	this.setState({showModal: true});
    }

    closePlayer() {
	this.setState({showPlayer: false});
	this.setState({videoobj: {}});
    }

    playVideo(videoObject) {
	this.setState({showModal: false});
	this.setState({showPlayer: true});
	this.setState({videoobj: videoObject});
	this.setState({modalVidObj: {}});
    }


    render() {

	var videoObj = this.state.videoobj || {};
	var lresults= this.state.results;



	return (
	    <div style={{paddingTop: "10px"}}>

	    <div style={{color: "white",  margin: 0}}>
	    <Grid style={{border: "0px solid red", }} fluid={true}>

	    <Row style={{border: "0px dashed pink",  padding: "0px", flex: "none"}}>
	    <Col md={12} xs={12} sm={12} lg={12} style={{border: "0px solid orange", margin: 0, padding: 0}}>

	    <Navbar  browse={true} fluid={true}>
	    <Navbar.Form >
	    <form onSubmit={this.fetchResults.bind(this)} >
	    <FormGroup style={{minWidth: "50%", maxWidth: "75%" }} >
	    <FormControl type="text" placeholder="Search by Title, Channel, Rating, Genre, Actor"  validations={{matchRegexp: /^[A-Za-z0-9]{1,30}$/}} style={{width: "100%", textAlign: "middle"}}  />
	    </FormGroup>
	    {' '}
	    <Button type="submit" >Search</Button>
	    </form>
	    </Navbar.Form>
	    </Navbar>

	    </Col>
	    </Row>
	    <Row style={{border: "0px dashed yellow",  padding: "0px", flex: 1}}>

	    <Col xs={12} sm={12} md={12} lg={12} style={{overflowY: "scroll",  margin: 0, padding: 0, paddingLeft: 10, maxHeight: "100vh"}}>

 	    { lresults.map((eachShow) => {
		return (<Lockup 
		    key={eachShow.progid} 
		    clicker={this.showVideo.bind(this,eachShow)} 
		    entry={eachShow} 
		    style={{padding: "10px", verticalAlign: "top", display: "inline-block"}}
		    orientation="p" >{eachShow.title}</Lockup>)
	    })}

	    </Col>

	    </Row>

	    <Row style={{flex: "none", height: "10px"}}><Col xs={12} sm={12} lg={12} md={12} style={{ height: "10px"}} /></Row>
	    </Grid>
	    </div>

	    <LargeVideoModal diskQuota={this.state.diskQuota}  show={this.state.showModal} onHide={this.close.bind(this)} videoObject={this.state.modalVidObj} watch={this.playVideo.bind(this,this.state.modalVidObj)}  >
	    </LargeVideoModal>

	    <Modal keyboard={true} bsClass="videoModal modal" dialogClassName="videoPlayerModal" show={this.state.showPlayer} onHide={this.closePlayer.bind(this)} style={{width: "100%", height: "auto", position: "absolute", top: 0, left: 0, verticalAlign: "middle"}}>
	    <Modal.Header  id="videomodal" closeButton><Modal.Title>{videoObj.title} - {videoObj.channame}</Modal.Title></Modal.Header>
	    <Modal.Body>
	    <Video key={videoObj.progid} title="Video" url={videoObj.hlsurl} />
	    </Modal.Body>
	    </Modal>



	    </div>

	    


	);
	
    }


}

module.exports = SearchView;