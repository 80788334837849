'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
import LazyLoad from 'react-lazy-load';
var globalServerName = "tv.9st.one";
var { Row, Col } = require('react-bootstrap');

var {
    Component
} = React;

class LockupRow extends Component {

    componentDidMount() {
    }

    constructor(props) {
	super(props);
//	this.state = {
//	}

    }

    epgImgUrl (epgid,width,height) {
	var url = "?is=Md";
	if (height>width) { // portrait
	    url = url + "&as=2x3";
	} else { // landscape
	    url = url + "&as=4x3";
	} 
	// with text
	url = url + "&it=true&s=" + epgid;
	//var finalURL = "https://" + globalServerName + "/tmsdata/pi" + url;
	var finalURL = "/tmsdata/pi" + url;
	return finalURL;
    }


    makeImgItem (url,width,height,imageStyle) {
	let gif = "data:image/gif;base64,R0lGODlhAQABAIAAAP///////yH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="; 
	return (<LazyLoad throttle={10} height={height} offset={150}><img className={imageStyle} src={url} height={height} width={width} /></LazyLoad>);
    }
    
    makeEpgImg (epgid,width,height,style) {
	var url = this.epgImgUrl(epgid,width,height);
	return this.makeImgItem(url,width,height,style);
    }



    logoURL (chanid) {
	//return "https://" + globalServerName + "/logos/" + chanid + ".png";
	return "/logos/" + chanid + ".png";
    }



    render() {

	var entry = this.props.entry;
	var img = this.makeEpgImg(entry.epgid,120,180,"square");
	var logoURL = this.logoURL(entry.guideid);


        var startTime = new Date(entry.gmtepochstart * 1000);
        var stopTime = new Date(entry.gmtepochend * 1000);
	var rating = (entry.rating || entry.mpaa);
	var channel = entry.channum;


	return (<Row key={entry.progid} style={{ borderTop: "1px solid #aaa", borderBottom: "1px solid black"  }}>
	    <Col xs={10}><div style={{padding: "4px"}}>
	    <Row style={{fontWeight: "bold", fontSize: "16px"}}> {entry.title} </Row>
	    <Row style={{fontWeight: "none", fontSize: "12px", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}> {entry.subtitle} &nbsp; </Row>
	    <Row>
	         <Col xs={5} style={{fontSize: "13px", color: "lightgray", padding: "0px"}}>{startTime.timeNow()} - {stopTime.timeNow()}</Col>
	         <Col xs={3} style={{fontSize: "13px", color: "lightgray"}}>{rating}</Col>
	         <Col xs={1} style={{fontSize: "13px", color: "lightgray"}}>{channel}</Col>
	    </Row>
	    </div>
	    </Col>

	    <Col xs={2}>
	      <div  className="logocontainer" > <img src={logoURL} className="logoimg logo-shadow"/> </div>
	    </Col>


	    </Row>);
    }    


}

module.exports = LockupRow;



Date.prototype.timeNow = function(){
    return ((this.getHours() === 0 ? 12 : ((this.getHours()>12) ? (this.getHours()-12) : this.getHours()))
            +":"
	  + ((this.getMinutes() < 10)?"0":"") + this.getMinutes()
	    //+":"                                                                                                                                                                                    
            //+ ((this.getSeconds() < 10)?"0":"") + this.getSeconds()            
	    +" "
	  + ((this.getHours()>11) ? ('PM'):'AM')
    );
};

Date.prototype.timeLittle = function(){
    return ((this.getHours() === 0 ? 12 : ((this.getHours()>12) ? (this.getHours()-12) : this.getHours()))
	    +":"
	  + ((this.getMinutes() < 10)?"0":"") + this.getMinutes()
            //+":"            
	    //+ ((this.getSeconds() < 10)?"0":"") + this.getSeconds()            
            +" "
          + ((this.getHours()>11) ? ('p.m.'):'a.m.')
    );
};

Date.prototype.timeNoLetters = function(){
    return ((this.getHours() === 0 ? 12 : ((this.getHours()>12) ? (this.getHours()-12) : this.getHours()))
            +":"
          + ((this.getMinutes() < 10)?"0":"") + this.getMinutes()
            //+":"            
            //+":"            
            //+ ((this.getSeconds() < 10)?"0":"") + this.getSeconds()            
    );
};

