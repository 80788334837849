'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
var View = require('./View');
var Text = require('./Text');
var BTServer = require('./BTServer');

var { Button, ButtonGroup, Form, FormGroup, ControlLabel, Grid, Row, Col, Modal } = require('react-bootstrap');

//import Video from './Video';

var {
    Component
} = React;


class Series extends Component {


    componentDidMount() {

    }

    constructor(props) {
	super(props);

	var seriesObj = {};
	seriesObj.s = props.videoObject.recStationid || props.videoObject.guideid;
	seriesObj.n = props.videoObject.recOnlynew || 1; // default to yes
	seriesObj.m = props.videoObject.recMaxepisodes || 5; // default to 5
	seriesObj.d = props.videoObject.recDeleteold || 1;
	seriesObj.ts = props.videoObject.recTimeslot || '';
	seriesObj.dw = props.videoObject.recDayofweek || '';

	this.state = {
	    alert: false,
	    alertMessage: '',
	    alertTitle: '',
	    videoObj: props.videoObject,
	    seriesObj: seriesObj
	}

    }


    componentWillReceiveProps(newProps) {
	var seriesObj = {};
	seriesObj.s = newProps.videoObject.recStationid || newProps.videoObject.guideid;
	seriesObj.n = newProps.videoObject.recOnlynew || 1; // default to yes
	seriesObj.m = newProps.videoObject.recMaxepisodes || 5; // default to 5
	seriesObj.d = newProps.videoObject.recDeleteold || 1;
	seriesObj.ts = newProps.videoObject.recTimeslot || '';
	seriesObj.dw = newProps.videoObject.recDayofweek || '';

	this.setState({videoObj: newProps.videoObject,
		       seriesObj: seriesObj });
    }


    recSrsVideo(videoObject,seriesObj) {
	if (videoObject.progid) {
	    this.setState({alert: true,
			   alertTitle: 'Recording Status',
			   alertMessage: "Scheduling...",
	    });
	    
	    BTServer.sendCommand('setseriesrec',['sho|'+videoObject.epgid,'s|'+seriesObj.s,'n|'+seriesObj.n,'m|'+seriesObj.m,'d|'+seriesObj.d,'ts|'+seriesObj.ts,'dw|'+seriesObj.dw],(results) => {
		
		if (results.result == "OK") {
		    videoObject.recorded = 1;
		    this.setState({alert: true,
				   alertTitle: 'Recording Status',
				   alertMessage: results.resdesc,
				   videoObj: videoObject
		    });
		    
		} 
	    });
	}
    }

    changeSeriesProp(prop,value) {
	var seriesObj = this.state.seriesObj;
	seriesObj[prop] = value;
	this.setState({seriesObj: seriesObj});
    }


    render() {


	// We need the station name from the recStationid


	var seriesObj = this.state.seriesObj;
	var videoObject = this.state.videoObj;

	return (<View style={{ padding: "10px"}}>
	    <Form horizontal>
	    <Row><Col md={12} style={{textAlign: "center"}}><h4>Configure Series Recordings</h4></Col></Row>
	    
	    <FormGroup>
	    <Col md={6} componentClass={ControlLabel}>Station:</Col>
	    <Col md={6}><ButtonGroup>
	    <Button active={seriesObj.s === '*'} onClick={()=>this.changeSeriesProp('s', '*')}>All</Button>
	    <Button active={seriesObj.s===videoObject.guideid} onClick={()=>this.changeSeriesProp('s', videoObject.guideid)}>This one ({this.state.videoObj.callsign})</Button>
</ButtonGroup></Col>
	    </FormGroup>

	    <FormGroup>
	    <Col md={6}  componentClass={ControlLabel}>Max Episodes:</Col>
	    <Col md={6}><ButtonGroup>
	    <Button active={seriesObj.m == 5} onClick={()=>this.changeSeriesProp('m',5)}>5</Button>
	    <Button active={seriesObj.m != 5} onClick={()=>this.changeSeriesProp('m',10)}>10</Button>
	    </ButtonGroup></Col>
	    </FormGroup>

	    <FormGroup>
	    <Col md={6} componentClass={ControlLabel}>Record New Episodes Only:</Col>
	    <Col md={6}><ButtonGroup>
	    <Button active={seriesObj.n==1} onClick={()=>this.changeSeriesProp('n',1)}>Yes</Button>
	    <Button active={seriesObj.n==0} onClick={()=>this.changeSeriesProp('n',0)}>No</Button>
	    </ButtonGroup></Col>
	    </FormGroup>

	    <FormGroup>
	    <Col md={6} componentClass={ControlLabel}>Automatically Delete Old:</Col>
	    <Col md={6}><ButtonGroup>
	    <Button active={seriesObj.d==1} onClick={()=>this.changeSeriesProp('d',1)}>Yes</Button>
	    <Button active={seriesObj.d==0} onClick={()=>this.changeSeriesProp('d',0)}>No</Button>
	    </ButtonGroup></Col>
	    </FormGroup>


	    <Row>
	    <Col md={12} style={{textAlign: "center"}}><Button bsStyle="primary" onClick={()=>this.recSrsVideo(videoObject,seriesObj)}>SAVE CHANGES</Button></Col>
	    </Row>
	    <Row><Col md={12} style={{textAlign: "center"}}>
	    <View>{this.state.alert ?  this.state.alertMessage : ""}</View>
	    </Col>
	    </Row>
	    </Form>

	    </View>);


    }

}

module.exports = Series;

