'use strict';

var React = require('react');
var ReactDOM = require('react-dom');

var {
    Tab,
    Tabs,
    Nav,
    Navbar,
    NavItem,
    Row,
    Col,
} = require('react-bootstrap');

var {
    Component
} = React;


var Featured = require('./Featured');
var Recordings = require('./Recordings');
var NowShowing = require('./NowShowing');
var Channels = require('./Channels');
var Browse = require('./Browse');
var SearchView = require('./Search');
var Manage = require('./Manage');




class AppContainer extends Component {

    componentDidMount() {


    }

    constructor(props) {
	super(props);

	this.state = {
	    key: 1, // default page
	    selected: [false,false,false,false,false],
	}

    }

    handleSelect(newkey) {
	this.state.selected[newkey] = true;
	this.setState({selected: this.state.selected});
	this.setState({key: newkey});
    }

    render() {

	var orgName = localStorage.getItem('organization');

	var newway = (

	    <Tab.Container id="mainTab" activeKey={this.state.key} onSelect={this.handleSelect.bind(this)} style={{padding: 0, margin: 0}}>
	    <Row className="clearfix">
	    <Col sm={12} style={{padding: 0, margin: "auto", paddingTop: "40px",  border: "0px solid green"}}>

	    <Navbar fixedTop={true}  fluid={true}>

	    <Navbar.Text pullRight={true} style={{fontWeight: "300", paddingRight: "20px"}}><Navbar.Toggle />{orgName}</Navbar.Text>

	    <Navbar.Collapse>
	    <Nav >
	    <NavItem eventKey={1}>Featured</NavItem>
	    <NavItem eventKey={2}>Recordings</NavItem>
	    <NavItem eventKey={3}>Now Showing</NavItem>
	    <NavItem eventKey={4}>Browse</NavItem>
	    <NavItem eventKey={5}>Search...</NavItem>
	    <NavItem eventKey={6}>Manage</NavItem>
	    </Nav>

	    </Navbar.Collapse>


	    </Navbar>

	    <Tab.Content animation >
	    <Tab.Pane eventKey={1} ><Featured  selected={this.state.key === 1} key={this.state.selected[1]} /></Tab.Pane>
	    <Tab.Pane eventKey={2} ><Recordings selected={this.state.key === 2} key={this.state.selected[2]} /></Tab.Pane>
	    <Tab.Pane eventKey={3} unmountOnExit={true} ><NowShowing selected={this.state.key === 3} key={this.state.selected[3]} /></Tab.Pane>
     	    <Tab.Pane eventKey={4} ><Browse selected={this.state.key === 4} key={this.state.selected[4]} /></Tab.Pane>
            <Tab.Pane eventKey={5} ><SearchView selected={this.state.key === 5} key={this.state.selected[5]} /></Tab.Pane>
            <Tab.Pane eventKey={6} ><Manage selected={this.state.key === 6} key={this.state.selected[6]} /></Tab.Pane>
	    </Tab.Content>

	    </Col>
	    </Row>
	    
	    </Tab.Container>


	);

	//return original;
	return newway;

	
	
    }


/*







	    <Navbar>
	    <Navbar.Header><Navbar.Brand>{orgName}</Navbar.Brand></Navbar.Header>

	    <Nav bsStyle="pills">
	    <NavItem eventKey={1}>Featured</NavItem>
	    <NavItem eventKey={2}>Recordings</NavItem>
	    <NavItem eventKey={3}>Now Showing</NavItem>
	    <NavItem eventKey={4}>Browse</NavItem>
	    <NavItem eventKey={5}>Search...</NavItem>
	    </Nav>

	    </Navbar>


	    <Tab.Content animation>
	    <Tab.Pane eventKey={1} title="Featured"><Featured  selected={this.state.key === 1} key={this.state.selected[1]} /></Tab.Pane>
	    <Tab.Pane eventKey={2} title="Recordings"><Recordings selected={this.state.key === 2} key={this.state.selected[2]} /></Tab.Pane>
	    <Tab.Pane eventKey={3} title="Now Showing"><NowShowing selected={this.state.key === 3} key={this.state.selected[3]} /></Tab.Pane>
     	    <Tab.Pane eventKey={4} title="Browse"><Browse selected={this.state.key === 4} key={this.state.selected[4]} /></Tab.Pane>
            <Tab.Pane eventKey={5} title="Search..."><SearchView selected={this.state.key === 5} key={this.state.selected[5]} /></Tab.Pane>
	    </Tab.Content>

*/


// 	    <Tab eventKey={4} title="Channels"><Channels selected={this.state.key === 4} key={this.state.selected[4]} /></Tab>



}

module.exports = AppContainer;