'use strict';

var React = require('react');
var ReactDOM = require('react-dom');

var View = require('./View');
var Text = require('./Text');
//var Lockup = require('./Lockup');
//var LargeVideoModal = require('./LargeVideoModal');
//var TimerMixin = require('react-timer-mixin');
var {Grid, Row, Col, Button, ButtonGroup, Modal, Nav, Navbar, NavItem, MenuItem, NavDropdown, ProgressBar} = require('react-bootstrap');

var globalServerName = 'tv.9st.one';

import BTServer from './BTServer';


var {
    Component
} = React;


class MgSeries extends Component {

    componentDidMount() {
//	//console.log("MgSeries mount fired, propsstate: " + this.props.selected );
	//if (this.props.selected) { this.fetchFeed();  }

    }

    constructor(props) {
	super(props);
	
	this.state = {
	    dataSet: [],
	    diskQuota: { used: 0, assigned: 0 },
	    seriesObj: {}

	};
    }


    componentDidMount() {
//	//console.log("MgRecordings mount fired, propsstate: " + this.props.selected );
	if (this.props.selected) { this.fetchRecordings();  }

    }


    fetchRecordings() {

	var self = this;

	BTServer.sendCommand('getseriesrec',[],(qresults) => {
	    
	    if (qresults.result === "OK") {
//		//console.log("reloaded recording data");  
		
// Add stuff for (auto)refreshing page, etc.

		var results = {};
		var payload = qresults.payload;
		var quota = qresults.quota;

		self.setState({ 
		    dataSet: payload,
		    diskQuota: quota
		});
		
	
	    }

							    
	});							    

    }



    delSeries(entry) {
	
	    var reallydelete = confirm("Do you really want to delete \"" + entry.title + "\"?");
	    if (reallydelete == true) {
		this.setState({alert: true,
			       alertTitle: 'Scheduling Status',
			       alertMessage: "Canceling...",
		});
		BTServer.sendCommand('cancelSeries',["sho|"+entry.shoid],(results) => {
		    if (results.result == "OK") {
			
			this.setState({alert: false,
				       alertTitle: '',
				       alertMessage: '',
			});
			this.fetchRecordings();
		    
		    }
		});
	    }

    }


    changeSeriesProp(entry,prop,value) {
	var seriesObj = entry;
	seriesObj[prop] = value;
	var self = this;
	
	    BTServer.sendCommand('setseriesrec',['sho|'+seriesObj.shoid,'s|'+seriesObj.stationid,'n|'+seriesObj.onlynew,'m|'+seriesObj.maxepisodes,'d|'+seriesObj.deleteold,'ts|'+seriesObj.timeslot,'dw|'+seriesObj.dayofweek],(results) => {

//		//console.log("Submitted series update: ");
		
		
		if (results.result == "OK") {
		    this.setState({alert: true,
				   alertTitle: 'Recording Status',
				   alertMessage: results.resdesc,
		    });
		    self.fetchRecordings();
		} 
	    });

    }



    renderRow(entry) {
//	var editbtn = (  
//	    <Button bsStyle="information" style={{  textAlign: "center", width: 80, padding: 4}} onClick={() => this.editSeries(entry)}>EDIT</Button> 
//	);
	var delbtn = (  
	    <Button bsStyle="warning" style={{  textAlign: "center", width: 80, padding: 4}} onClick={() => this.delSeries(entry)}>CANCEL</Button> 
	);

	var maxepisodes = (<ButtonGroup>
	    <Button active={entry.maxepisodes==="5"} onClick={()=>this.changeSeriesProp(entry,'maxepisodes',5)}>5</Button>
	    <Button active={entry.maxepisodes!=="5"} onClick={()=>this.changeSeriesProp(entry,'maxepisodes',10)}>10</Button>
	    </ButtonGroup>);

	var onlynew = (<ButtonGroup>
	    <Button active={entry.onlynew==="1"} onClick={()=>this.changeSeriesProp(entry,'onlynew',1)}>Yes</Button>
	    <Button active={entry.onlynew==="0"} onClick={()=>this.changeSeriesProp(entry,'onlynew',0)}>No</Button>
	    </ButtonGroup>);

	var deleteold = (<ButtonGroup>
	    <Button active={entry.deleteold==="1"} onClick={()=>this.changeSeriesProp(entry,'deleteold',1)}>Yes</Button>
	    <Button active={entry.deleteold==="0"} onClick={()=>this.changeSeriesProp(entry,'deleteold',0)}>No</Button>
	    </ButtonGroup>);
	

	return (
	    <tr key={entry.shoid}>
	    <td className="recordingTD" style={{ minWidth: "150px" }}> {entry.title} </td>
	    <td className="recordingTD" style={{ minWidth: "150px"}}>  {entry.stationInfo.name} (Ch. {entry.stationInfo.channel}) </td>
	    <td className="recordingTD" style={{ minWidth: "85px"}}>  {delbtn}  </td>
	    <td className="recordingTD" style={{ minWidth: "125px"}}>  {maxepisodes}  </td>
	    <td className="recordingTD" style={{ minWidth: "125px" }}>  {onlynew}</td>
	    <td className="recordingTD" style={{ minWidth: "125px"}}>  {deleteold}  </td>
	    </tr>);


    }
    
    render() {

	var quotaPercentage = ((this.state.diskQuota.used / this.state.diskQuota.assigned) * 100).toFixed(0);
	var dataSet = this.state.dataSet;
	var self = this;


	return (


	    <div style={{paddingBottom: "10px", paddingTop: "10px"}}>
	    
	    <div style={{ paddingLeft: "10px", paddingRight: "10px"}}>
	    
	    </div>
	    
	    <View style={{padding: 0, margin: 0}}>	
	    
	    <table className="recordingTable"><tbody>
	    <tr><th>Title</th><th>Channel</th><th>Actions</th><th>Max Episodes</th><th>Only New</th><th>Auto Delete Old</th></tr>
	    
	    { dataSet.map((entry) => this.renderRow(entry)) }
	    
	    </tbody>
	    </table>
	    </View>


	    </div>

	);


    }

}

module.exports = MgSeries;
