'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
import LazyLoad from 'react-lazy-load';

var globalServerName = "tv.9st.one";

var {
    Component
} = React;

class Lockup extends Component {

    componentDidMount() {
    }

    constructor(props) {
	super(props);
//	this.state = {
//	}

    }

    epgImgUrl (epgid,width,height) {
	var url = "?is=Md";
	if (height>width) { // portrait
	    url = url + "&as=2x3";
	} else { // landscape
	    url = url + "&as=4x3";
	} 
	// with text
	url = url + "&it=true&s=" + epgid;
	//var finalURL = "https://" + globalServerName + "/tmsdata/pi" + url;
	var finalURL = "/tmsdata/pi" + url;
	return finalURL;
    }


    makeImgItem (url,width,height,imageStyle) {
	let gif = "data:image/gif;base64,R0lGODlhAQABAIAAAP///////yH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="; 
	return (<LazyLoad throttle={10} height={height} offset={150}><img className={imageStyle} src={url} height={height} width={width} /></LazyLoad>);
    }
    
    makeEpgImg (epgid,width,height,style) {
	var url = this.epgImgUrl(epgid,width,height);
	return this.makeImgItem(url,width,height,style);
    }

    render() {

	var entry = this.props.entry;
	var orientation = this.props.orientation;
	var img;

	var textwidth; 
        if (orientation === "p") {
            img = this.makeEpgImg(entry.epgid,120,180,"square");
	    textwidth = 120;
        } else {
            img = this.makeEpgImg(entry.epgid,240,180,"rounded");
	    textwidth = 240;
        }

	var title = entry.title;
	if (entry.epgid.match(/EP|SH/)  && entry.subtitle){
	    title = entry.subtitle;
	}

	return (<div className="wrapper" onClick={this.props.clicker} >
	    <div style={{maxWidth: textwidth}}> { img } </div>
	    <div style={{width: textwidth }} className="titleText"><span> {title} </span></div>
	    </div>);
    }    


}

module.exports = Lockup;