var React = require('react');
var ReactDOM = require('react-dom');
var {
    Component
} = React;

var { ResponsiveEmbed } = require('react-bootstrap');

class Video extends Component {

    constructor(props) {
	super(props);
	this.state = { 
	    videoPlayer: { dispose: function() { console.log("Default video player dispose function"); },
			   destroy: function() { console.log("Default video player destroy function"); } 
	    }
	};
    }
    
    componentWillUnmount() {
	//console.log("Video going bye bye");
	var videoPlayer = this.state.videoPlayer;
	//videoPlayer.pause();
	videoPlayer.src="";
	//videoPlayer.load();
	videoPlayer.destroy();
	//videoPlayer.dispose();

    }

    componentDidMount() {

	var video, wrapper;
	//var hls = new Hls();

	wrapper = document.createElement('div');

	if (this.props.url) {

	    var browser = this.sayswho();

	    wrapper.innerHTML = "<video id='attachmentVideo' class='video-js vjs-default-skin video-btv'  controls fluid x-webkit-airplay='allow'><p className='vjs-no-js'>To view this video please enable JavaScript, and consider upgrading to a web browser that <a href='http://videojs.com/html5-video-support/' target='_blank'>supports HTML5 video</a></p></video>";

	    video = wrapper.firstChild;
	    this.refs.target.appendChild(video);
	    
	    var videoPlayer;

	    if (browser.name === "Safari") {

		video.setAttribute("src",this.props.url);
		video.setAttribute("autoplay",true);
		videoPlayer = video;
		videoPlayer.destroy = function destroy() {
		    this.pause();
		    this.src = "";
		    this.load();
		}

	    } else {
	    // video-js


	    // flowplapyer
//	    wrapper.innerHTML = "<div id='attachmentVideo' class='video-btv flowplayer'></div>";

	    //wrapper.innerHTML = "<video id='attachmentVideo' class='video-js video-btv'   x-webkit-airplay='allow' controls  autoplay fluid></video>";




	    var hlsConfig = {
		maxBufferLength: 30,
		maxBufferSize: 30*1000*1000,
		//maxBufferHole: 2,
		//maxSeekHole: 2,
		//liveSyncDurationCount: 18,
		//liveMaxLatencyDurationCount: 24,
		//stretchShortVideoTrack: true,
		debug: false,
	    };

	    //var videoPlayer = new Hls();

		videoPlayer = new Hls(hlsConfig);
		videoPlayer.loadSource(this.props.url);
		videoPlayer.attachMedia(video);
		videoPlayer.on(Hls.Events.MANIFEST_PARSED, function() {
		    video.play();
		});
		

	    	videoPlayer.on(Hls.Events.ERROR, function (event, data) {
		    if (data.fatal) {
			//		 if (false) {
			switch(data.type) {
			    case Hls.ErrorTypes.NETWORK_ERROR:
				// try to recover network error
				//console.log("fatal network error encountered, try to recover");
				videoPlayer.startLoad();
				break;
			    case Hls.ErrorTypes.MEDIA_ERROR:
				console.log("fatal media error encountered, try to recover");
				videoPlayer.recoverMediaError();
				break;
			    default:
				// cannot recover
				console.log("can't recover from the error: " + data.details);
				videoPlayer.destroy();
				break;
			}
		    }
		});
		


	    // Flowplayer
	    //var videoPlayer = flowplayer(video, { live: !this.props.recorded, clip: {  sources: [ { type:"application/x-mpegURL", src: this.props.url } ]  }, autoplay: true });

	    // video-js
	    // Working with ffmpeg 

	    /*
	    var videoPlayer =   videojs(video, {"html5": { nativeTextTracks: true } });
	    videoPlayer.src({
		src: this.props.url,
		type: 'application/x-mpegURL',
		withCredentials: true
	    });
	    */

	    //videoPlayer.play();

	    }


	    //this.state.video = videoPlayer;
	    this.setState({videoPlayer: videoPlayer});
	    //console.log("Got a link, now playing");
	    return videoPlayer;
	} else {
//	    wrapper.innerHTML = "<video id='attachmentVideo' />";
	    return;
	}


	//hls.attachMedia(video);
	//hls.on(Hls.Events.MANIFEST_PARSED,function() {
	//video.play();
    //});
	

    }


    sayswho() {
	var ua= navigator.userAgent, tem, 
	    M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
	if(/trident/i.test(M[1])){
            tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
            return {name:'IE',version:(tem[1] || '')};
	}
	if(M[1]=== 'Chrome'){
            tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
            if(tem!= null) return {name:tem[1].replace('OPR', 'Opera'),version:tem[2]};
	}
	M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
	if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
	return {name:M[0],version:M[1]};
    }



    render() {

	//console.log("props.open = " + this.props.open);
	if (this.props.open===false) {
	    //console.log("We're playing, but have been asked to dispose " + this.state.video);
	    //this.state.video.pause();
	    //this.state.videoPlayer.dispose();
	    this.state.videoPlayer.destroy();
	}

	return (
	    <div id="videoContainer" className="videoWrapperContaner">
	    <div id="dummyVideoPad" className="videoWrapperDummy" />
	    <div id="attachmentVideoContainer" ref="target" className="videoWrapper" />
	    </div>
	);

    }

}



module.exports = Video;