'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
var Bootstrap = require('react-bootstrap');
var { Grid, Row, Col, Button, ButtonGroup, Jumbotron, FormGroup, FormControl } = require('react-bootstrap');

var {
    Component
} = React;


class Login extends Component {
    
    constructor(props) {
        super(props);
    
        this.state = {
            showProgress: false,
	    success: false,
        }
    }


    onLoginPressed() {
	
	//console.log('Attempting login with username ' + this.state.userid + ' and pass ' + this.state.userkey);
        this.setState({showProgress: true });
	
	var authService = require('./AuthService');
	authService.login({
            userid: this.state.userid,
            userkey: this.state.userkey
        }, (results) => {
            this.setState(Object.assign({ showProgress: false }, results));
	    
	    if (results.success) {
		//console.log("Successful login");
		this.setState({success: true});
		this.props.onLogin();
	    }
	    
	});
    }

    
    render () {

        var errorCtrl = <div />
        var activityIndicator = <div />

	var mainForm = (
	    <div>
	    <div style={{maxWidth: "100%", minWidth: "400px", position: "absolute", left: "50%", top: "50%", marginLeft: "-200px", marginTop: "-200px" }}>
	    <h1>Welcome to Your TV</h1>
            <p>Enter your username and password</p>
	    
	    <FormGroup>
            <FormControl type="text" className="login" 
            onChange={(text)=> this.setState({"userid": text.target.value})}
            placeholder="User" />
	    </FormGroup>
	    <FormGroup>
            <FormControl type="password" className="login" 
            onChange={(text)=> this.setState({"userkey": text.target.value})}
            placeholder="Password" />
            </FormGroup>
            <Button className="login"  onClick={this.onLoginPressed.bind(this)} >Log In </Button>

	    </div>
	    </div>
	);



        if (!this.state.success && this.state.badCredentials) {
            errorCtrl = <h2>
                Invalid login.
                </h2>;
        }

        if (!this.state.success && this.state.unknownError) {

             errorCtrl = <h2>
                An unknown error occurred.
                </h2>;

        }

        if (this.state.success) {
	    //console.log("Successfully logged in");
            errorCtrl = (<h3>Login successful.</h3>);
	    mainForm = "";
        } else {
	    //console.log("Not successfully logged in");
	}



        if (this.state.showProgress) {
            activityIndicator = <h3>Checking credentials...</h3>;
        } 

	

        return (
	    
            <div>
	    {mainForm}
            {errorCtrl}
            {activityIndicator}
	    
            </div>
            
        );
    }
    
}



module.exports = Login;
