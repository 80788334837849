'use strict';

var React = require('react');
var ReactDOM = require('react-dom');

var View = require('./View');
var Text = require('./Text');
//var Lockup = require('./Lockup');
//var LargeVideoModal = require('./LargeVideoModal');
//var TimerMixin = require('react-timer-mixin');
var {Grid, Row, Col, Button, ButtonGroup, Modal, Nav, Navbar, NavItem, MenuItem, NavDropdown, ProgressBar} = require('react-bootstrap');

var globalServerName = 'tv.9st.one';

import BTServer from './BTServer';
import Video from './Video';

var {
    Component
} = React;


class MgRecordings extends Component {

    constructor(props) {
	super(props);
	
	this.state = {
	    dataSet: [],
	    diskQuota: { used: 0, assigned: 0 },
	    showPlayer: false,
	    videoObj: {},

	};


    }


    componentDidMount() {
	//console.log("MgRecordings mount fired, propsstate: " + this.props.selected );
	if (this.props.selected) { this.fetchRecordings();  }

    }


    fetchRecordings() {

	var self = this;

	BTServer.sendCommand('getrec',['hlsonly'],(qresults) => {
	    
	    if (qresults.result === "OK") {
		//console.log("reloaded recording data");  
		
// Add stuff for (auto)refreshing page, etc.

		var results = {};
		var payload = qresults.payload;
		var quota = qresults.quota;

		self.setState({ 
		    dataSet: payload,
		    diskQuota: quota
		});
		
	
	    }

							    
	});							    

    }

    delVideo(videoObject) {
	//console.log("Delete pressed");
	if (videoObject.recorded || videoObject.progid) {
	    var reallydelete = confirm("Do you really want to delete \"" + videoObject.title + "\"?");
	    if (reallydelete == true) {
		this.setState({alert: true,
			       alertTitle: 'Recording Status',
			       alertMessage: "Canceling...",
		});
		BTServer.sendCommand('delrechls',[videoObject.recorded],(results) => {
		
		    if (results.result == "OK") {
			videoObject.recorded = 0;
			
			this.setState({alert: false,
				       alertTitle: '',
				       alertMessage: '',
				       videoObj: {}
			    
			});
			this.fetchRecordings();
		    }
		});
	    }
	}
    }



    closePlayer() {
	this.setState({showPlayer: false});
	this.setState({videoObj: {}});
    }

    playVideo(videoObject) {
	this.setState({showPlayer: true});
	this.setState({videoObj: videoObject});
    }


    renderRow(entry) {

	var playbtn = (  
	    <Button bsStyle="success" style={{  textAlign: "center", width: 80, padding: 4}} onClick={() => this.playVideo(entry)}>WATCH</Button> 
	);
	var delbtn = (  
	    <Button bsStyle="danger" style={{  textAlign: "center", width: 80, padding: 4}} onClick={() => this.delVideo(entry)}>DELETE</Button> 
	);


	return (
	    <tr key={entry.recorded}>
	    <td className="recordingTD" style={{ width: "250px" }}> {entry.title}<br /><small>{entry.subtitle}</small></td>
	    <td className="recordingTD" style={{ width: "50px"}} > <br style={{ height: "10px" }} /><ProgressBar style={{  height: "8px", position: "relative" }} bsStyle="success" now={(entry.duration * 60) / entry.progress} /></td>
	    <td className="recordingTD" style={{ width: "185px" }}> {playbtn} {entry.candelete ? delbtn : ""}</td>
	    <td className="recordingTD" style={{ }}><small>{entry.description}</small></td>
	    </tr>);
    }
    


    render() {

	var quotaPercentage = ((this.state.diskQuota.used / this.state.diskQuota.assigned) * 100).toFixed(0);
	var progressStyle = "success";
	if (quotaPercentage > 80) { progressStyle = "warning"; }
	if (quotaPercentage > 98) { progressStyle = "danger"; }


	var dataSet = this.state.dataSet;

//	    <ProgressBar bsStyle={progressStyle} now={parseInt(quotaPercentage)} label={"Storage Used: " + quotaPercentage + '%'} />

/*

*/
	var videoObj = this.state.videoObj || {};
	var self = this;

	return (
	    <div style={{paddingBottom: "10px", paddingTop: "10px"}}>

	    <div style={{ paddingLeft: "10px", paddingRight: "10px"}}>

	    </div>

	    <View style={{padding: 0, margin: 0}}>	

	    <table className="recordingTable"><tbody>
	    <tr><th>Title</th><th>Progress</th><th>Actions</th><th>Description</th></tr>

	    { dataSet.map((entry) => this.renderRow(entry)) }

	    </tbody>
	    </table>
	    </View>



	    <Modal keyboard={true} bsClass="videoModal modal" dialogClassName="videoPlayerModal" show={this.state.showPlayer} onHide={this.closePlayer.bind(this)} style={{width: "100%", height: "auto", position: "absolute", top: 0, left: 0, verticalAlign: "middle"}}>
	    <Modal.Header id="videomodal" closeButton><Modal.Title>{videoObj.title}</Modal.Title></Modal.Header>
	    <Modal.Body>
	    <Video key={videoObj.progid} title="Video" url={videoObj.hlsurl} />
	    </Modal.Body>
	    </Modal>

	    </div>

	);

	}


}

module.exports = MgRecordings;
