'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
import LazyLoad from 'react-lazy-load';

var { Tooltip, OverlayTrigger } = require('react-bootstrap');
 
var globalServerName = "tv.9st.one";

var {
    Component
} = React;

class Lockup extends Component {

    componentDidMount() {
    }

    constructor(props) {
	super(props);
//	this.state = {
//	}

    }


    epgImgUrl (epgid,width,height) {
	var url = "?is=Md";
	if (height>width) { // portrait
	    url = url + "&as=2x3";
	} else { // landscape
	    url = url + "&as=4x3";
	} 
	// with text
	url = url + "&it=true&s=" + epgid;
	//var finalURL = "https://" + globalServerName + "/tmsdata/pi" + url;
	var finalURL = "/tmsdata/pi" + url;
	return finalURL;
    }


    makeImgItem (url,width,height,imageStyle) {
	let gif = "data:image/gif;base64,R0lGODlhAQABAIAAAP///////yH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="; 
//	var imgClass = "posterImg"; 
//	if (width>height) { imgClass = "posterImgRound"; }
	// className={imgClass} // removed from LazyLoad
	return (<LazyLoad throttle={10} height={height} offset={150} ><img className={imageStyle} src={url} height={height} width={width} /></LazyLoad>);
    }
    
    makeEpgImg (epgid,width,height,style) {
	var url = this.epgImgUrl(epgid,width,height);
	return this.makeImgItem(url,width,height,style);
    }

    render() {

	var entry = this.props.entry;
	var orientation = this.props.orientation;
	
	var img;

	var usTimeParts = entry.cleanstart.split(':');
	var suffix = "a";
	var timePart1 = parseInt(usTimeParts[0]);
	if (timePart1 > 11) {
	    timePart1 -= 12;
	    suffix = "p"; 
	} 
	if (timePart1 == 0) { timePart1 = 12 }
	var newTime = timePart1 + ":" + usTimeParts[1] + suffix;

	var extraClass = "posterImg";
	var textwidth; 
        if (orientation === "p") {
            img = this.makeEpgImg(entry.epgid,120,180,"square");
	    textwidth = 120;
        } else {
            img = this.makeEpgImg(entry.epgid,240,180,"rounded");
	    textwidth = 240;
        }

	var title = entry.title;
	if (entry.epgid.match(/EP|SH|SP/)  && entry.subtitle){
	    title = entry.subtitle;
	}


	const tooltipid = "tooltip" + entry.progid
	var nameToShow = entry.channame
	if (entry.chanaff) { nameToShow = entry.channame + " (" + entry.chanaff + ")" }
	const tooltip = (<Tooltip id={tooltipid}><strong>{nameToShow}</strong></Tooltip>);
	const overlayid = "overlay" + entry.progid
	
	    return (
		<OverlayTrigger id={overlayid} overlay={tooltip} placement="top">
		<div className="wrapper" onClick={this.props.clicker} style={{verticalAlign: "top"}} >
		<div style={{maxWidth: textwidth,  display:"inline-block", position: "relative"}} >
		{ entry.now === "true" ? <span className="picLabel greenGradient" style={{top: "6px", right: "-17px"}}>On Air</span> : <span /> }
		{ entry.now === "false" ? <span className="picLabel grayGradient" style={{top: "6px", right: "-17px"}}> {newTime} </span> : <span /> }
		{ img } </div>
		<div style={{width: textwidth }} className="titleText">{title}</div>
		</div>
		</OverlayTrigger>
	    );
	

    }    


}

module.exports = Lockup;